import dayjs from "dayjs";

export class CommonUtils {
  public dateFormat = (value: string, iPattern: string, oPattern: string) => {
    return dayjs(value, iPattern).format(oPattern);
  };

  // object 가 비었는지
  public isEmptyObj(obj: any) {
    if (obj === null || obj === undefined) return true;
    if (obj.constructor === Object && Object.keys(obj).length > 0) {
      return false;
    }

    return true;
  }

  // 3자리마다 , 변환 후 String으로 return
  public numericComma = (value: any) => {
    if (typeof value === "number") {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else if (typeof value === "string") return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return undefined;
  };

  // 난수 생성 (from ~ to)
  public getRandomNum = (min: number, max: number) => {
    let num = Math.floor(Math.random() * max + 1) + min;
    return num;
  };

  // 국가코드별 국기 이미지 생성기
  public makeCtryFlagUrl = (code: string) => {
    return `${process.env.REACT_APP_DT_IMAGE_DOMAIN}/images/aihdt/flag/flag_${code.toLowerCase()}.png`;
  };

  // S3 다운로드 URL 생성기
  public genS3DownloadUrl = (file: any) => {
    const encodedFilepath = encodeURIComponent(file.FILE_PATH);
    let downloadUrl = "";

    if (window.location.hostname === process.env.REACT_APP_PROD_URL) {
      // 운영서버는 도메인으로
      const S3ImageURL = process.env.REACT_APP_S3_IMAGE_URL_PROD;
      downloadUrl = "https://" + S3ImageURL + encodedFilepath;
    } else {
      // 개발서버는 S3 URL로 접근
      const S3BucketName = process.env.REACT_APP_S3_BUCKET_NAME_DEV;
      downloadUrl = "https://" + S3BucketName + ".s3.ap-northeast-2.amazonaws.com/" + encodedFilepath;
    }
    return downloadUrl;
  };

  // Text에서 Markdown Link 추출기
  public makeExtraLinks = (answer: string) => {
    const splits = answer.split("](");
    const titles: any = [];
    const links: any = [];
    for (let i = 0; i < splits.length; i++) {
      // 각행의 점부파일 이름 가져오기 (마지막 row는 첨부파일이 없음...)
      const text = splits[i];
      const pos = text.lastIndexOf("[");
      if (pos >= 0) {
        const title = text.substring(pos + 1, text.length);
        titles.push(title);
      }
      const linkPos = text.indexOf(")"); // 제일 처음 tag 닫히는 부분
      if (linkPos >= 0) {
        const link = text.substring(0, linkPos);
        links.push(link);
      }
    }
    let attachList: any = [];
    for (let i = 0; i < titles.length; i++) {
      const type: string = links[i].substring(links[i].lastIndexOf(".") + 1, links[i].length);
      attachList.push({ title: titles[i], link: links[i], type: type, timestamp: dayjs().format("YYYYMMDDHHmmssSSS") });
    }
    return attachList;
  };
}
