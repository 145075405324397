import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";

import { Box } from "@mui/material";

import { HttpMainApi } from "../../interface/main-api";

import { userState, ChatContentProps } from "../../interface/MainInterface";

import UserMessage from "./ChatListMessage/UserMessage";
import AgentMessage from "./ChatListMessage/AgentMessage";
import AiAnswer from "./ChatListMessage/AiAnswer";

interface propsType {
  userState: userState;
  isSearchMode: boolean;
  chatHistory: ChatContentProps[];
  isAiLoading: boolean;
  agentCode: string;
  agentName: string;
  aiAnswer: string;
  callback: any;
}

const mainApi = new HttpMainApi(
  window.location.hostname === process.env.REACT_APP_PROD_URL
    ? `${process.env.REACT_APP_API_URL_PROD}/adminMain`
    : `${process.env.REACT_APP_API_URL_DEV}/adminMain`
);

const ChatList = (props: propsType, ref: any) => {
  const scrollRef: any = useRef();

  const [highlightText, setHighlightText] = useState(""); // 채팅장 검색시 사용자가 입력한 단어
  const [isBottomScroll, setIsBottomScroll] = useState(true); // 스크롤 이동했는지
  const [stopScroll, setStopScroll] = useState(false); // 좋아요 등 처리할때 스크롤 이동 막기

  useEffect(() => {
    return () => {
      setHighlightText("");
      setIsBottomScroll(true);
      setStopScroll(false);
    };
  }, []);

  // Chat(parent) component에서 검색어 입력시 채팅 리스트 하이라이트 표시할 단어 세팅
  const setHighlight = (text: string) => {
    setHighlightText(text);
  };

  // 스크롤 제일 아래로 Method
  const scrollToBottom = () => {
    if (scrollRef.current && !stopScroll) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
    if (stopScroll) {
      setStopScroll(false);
    }
  };

  // 겸색결과 위/아래 네비게이션에서 해당 위치로 이동시 사용
  const scrollTo = (pos: number) => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = pos;
    }
  };

  // 좋아요 처리
  const fncLike = async (info: any) => {
    if (info.STATUS === "like") return;
    const param: any = {
      command: "update_information_sk",
      pk: props.userState.id + "#" + props.userState.company + "#" + props.agentName, // pk
      range: info.RANGE, // sk
      update_items: {
        editor: props.userState.id,
        category: "",
        contact: "",
        inquiry: "",
        status: "like",
      },
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      props.callback({ command: "finish_like_proc" });
    }
  };

  // 싫어요 처리 (설문 입력 폼 open)
  const fncDisLike = async (info: any) => {
    if (info.STATUS === "dislike") return;
    props.callback({ command: "dislike", info: info });
  };

  // 공통 이벤트 : 채팅창 변경시 스크롤 제일 아래로 정렬
  useEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chatHistory, props.aiAnswer, props.isAiLoading]);

  const scrollStop = (flag: boolean) => {
    setStopScroll(flag);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    setHighlight,
    scrollToBottom,
    scrollStop,
    scrollTo,
  }));
  return (
    <Box id="chatRoom" className={props.isSearchMode ? "chatRoom searchResult" : "chatRoom"} sx={{ position: "relative" }}>
      {/* 채팅 스크롤 처리 : 스크롤은 허용하지만 스크롤 바는 보이지 않도록 처리 */}
      <Box
        id="scroll"
        ref={scrollRef}
        className="scroll srcollBottom noScrollBar"
        onScroll={(e: any) => {
          setIsBottomScroll(
            // 스크롤이 맨 아래에 있는지 감지
            e.target.scrollTop >= e.target.scrollHeight - e.target.clientHeight
          );
        }}
      >
        {/* 채팅 본문 List*/}
        <Box>
          {props.chatHistory.map((row: any, index: number) =>
            row.ANSWER === "" ? (
              // 사용자 질문 영역
              <UserMessage key={index} userState={props.userState} row={row} index={index} highlightText={highlightText} />
            ) : (
              // AI 답변 History
              <AgentMessage
                key={index}
                userState={props.userState}
                row={row}
                index={index}
                highlightText={highlightText}
                scrollToBottom={scrollToBottom}
                callback={(payload: any) => {
                  if (payload.command === "like") {
                    setStopScroll(true);
                    fncLike(row);
                  } else if (payload.command === "dislike") {
                    setStopScroll(true);
                    fncDisLike(row);
                  }
                }}
              />
            )
          )}
          {/* AI 현재 답변중인 내용 (Stream 처리) */}
          {props.isAiLoading && <AiAnswer agentCode={props.agentCode} agentName={props.agentName} aiAnswer={props.aiAnswer} />}
        </Box>
      </Box>
      {/* 스크롤 아래로 이동 버튼 */}
      <div className="floatArea">
        <button
          className={isBottomScroll ? "btnDown hidden" : "btnDown"}
          onClick={() => {
            scrollToBottom();
          }}
        >
          맨아래로 이동
        </button>
      </div>
    </Box>
  );
};

export default forwardRef(ChatList);
