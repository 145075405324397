export abstract class ApiBase {
  protected apiName: string;
  protected urlPath: string;
  protected authToken: string | undefined;

  abstract onApiName(): string;
  abstract onUrlPath(): string;

  constructor() {
    this.apiName = this.onApiName();
    this.urlPath = this.onUrlPath();
    this.authToken = undefined;
  }

  protected async requestStream(requestBody: any): Promise<any> {
    if (this.apiName === "AiApi") {
      let response;
      // Stream 형식으로 Request
      if (window.location.hostname === process.env.REACT_APP_PROD_URL) {
        response = await fetch(process.env.REACT_APP_AI_API_URL_PROD + "", {
          method: "POST",
          body: JSON.stringify(requestBody),
        });
      } else {
        response = await fetch(process.env.REACT_APP_AI_API_URL_DEV + "", {
          method: "POST",
          body: JSON.stringify(requestBody),
        });
      }

      return response;
    }
  }
}
