import { useState, forwardRef, useImperativeHandle } from "react";

import { Dialog } from "@mui/material";

interface propsType {
  callback: any;
}

const AlertNoMorePin = (props: propsType, ref: any) => {
  const [openDialog, setOpenDialog] = useState(false);

  const open = () => {
    setOpenDialog(true);
  };
  const close = () => {
    setOpenDialog(false);
  };
  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <Dialog
      onClose={close}
      open={openDialog}
      PaperProps={{ style: { borderRadius: "16px", margin: "0px 30px", position: "absolute", top: "25vh" } }}
    >
      <div className="popupD popupAlert">
        <div className="popHeader">
          <p className="popTit">알림</p>
          <div className="btnAreaRight">
            <button className="btnClose popClose" onClick={close}>
              닫기
            </button>
          </div>
        </div>
        <div className="popCont">
          <div className="alertText">
            즐겨찾기는 최대 3개까지 가능합니다.
            <br />
            필요 시 기존 즐겨찾기를 해지 후 설정해주세요.
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default forwardRef(AlertNoMorePin);
