import { saveAs } from "file-saver";
import AWS from "aws-sdk";

import { CardMedia } from "@mui/material";

import { AcceptDocFiles, AcceptImageFiles } from "../../interface/MainInterface";

interface propsType {
  data: any;
  mode: string;
  callback: any;
}

AWS.config.update({
  region: "ap-northeast-2",
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
});

const AttachComponent = (props: propsType) => {
  const s3 = new AWS.S3();

  const s3Download = async (data: any) => {
    let bucketName: string | undefined = "";
    if (window.location.hostname === process.env.REACT_APP_PROD_URL) {
      bucketName = process.env.REACT_APP_S3_BUCKET_NAME_PROD;
    } else {
      bucketName = process.env.REACT_APP_S3_BUCKET_NAME_DEV;
    }
    const params = {
      Bucket: bucketName,
      Key: props.data.path,
      Expires: 1200,
    };

    const downloadUrl = s3.getSignedUrl("getObject", params);

    await fetch(downloadUrl, { method: "GET" })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        saveAs(blob, `${props.data.title}`);
      })
      .catch((err) => {
        console.error("err : ", err);
      });
  };
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className="docu"
      onClick={async () => {
        if (props.data.isS3) {
          s3Download(props.data);
        } else {
          window.open(props.data.link);
        }
      }}
    >
      {AcceptDocFiles.indexOf(props.data.type.toLowerCase()) > -1 && (
        <>
          <span className={`docuType ${props.data.type.toLowerCase()}`}>{props.data.type.toUpperCase()}</span>
          <span className="fileName">{props.data.title.split("." + props.data.type)[0]}</span>
        </>
      )}
      {props.mode === "chat" && AcceptImageFiles.indexOf(props.data.type.toLowerCase()) > -1 && (
        <>
          <span className={`docuType ${props.data.type.toLowerCase()}`}>{props.data.type.toUpperCase()}</span>
          <span className="fileName">{props.data.title.split("." + props.data.type)[0]}</span>
        </>
      )}
      {/* 이미지 타입 */}
      {props.mode === "box" && AcceptImageFiles.indexOf(props.data.type.toLowerCase()) > -1 && (
        <CardMedia component="img" image={props.data.link} />
      )}
    </a>
  );
};

export default AttachComponent;
