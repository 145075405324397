import { Routes, Route, Navigate } from "react-router-dom";

import AiMain from "../page/aiMain/AiMain";
import Chat from "../page/chat/Chat";
import Faq from "../page/faq/Faq";
import Qna from "../page/qna/Qna";
import TestIFrame from "../page/testIFrame/TestIFrame";
import DreamtourIFrame from "../page/testIFrame/DreamtourIFrame";

import { userState } from "../interface/MainInterface";
interface propsType {
  userState: userState;
  setUserState: any;
}

const MainConteiner = (props: propsType) => {

  return (
    <Routes>
      <Route path="/" element={<DreamtourIFrame />} />
      <Route path="/login" element={<DreamtourIFrame />} />
      <Route path="/aiVisa" element={<AiMain userState={props.userState} setUserState={props.setUserState} />} />
      <Route path="/chat/:code" element={<Chat userState={props.userState} />} />
      <Route path="/faq" element={<Faq userState={props.userState} />} />
      <Route path="/qna" element={<Qna userState={props.userState} />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default MainConteiner;
