import { useEffect, useState, useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

import { Box, Chip, Stack } from "@mui/material";

import { ChatSetProps, countries, userState } from "../../interface/MainInterface";
import { HttpMainApi } from "../../interface/main-api";

import ComboAiAgent from "../modules/ComboAiAgent";
import FooterNavigation from "../../mainContainer/FooterNavigation";

import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

interface propsType {
  userState: userState;
  setUserState: any;
}

const mainApi = new HttpMainApi(
  window.location.hostname === process.env.REACT_APP_PROD_URL
    ? `${process.env.REACT_APP_API_URL_PROD}/adminMain`
    : `${process.env.REACT_APP_API_URL_DEV}/adminMain`
);

const AiMain = (props: propsType) => {
  let navigate = useNavigate();
  const location = useLocation();
  // 쿼리 스트링으로 userId, userName 값 받기
  const queryParams: any = queryString.parse(location.search);

  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  // 최근 검색어 (현재는 사용안함... )
  const [recentSearch, setRecentSearch] = useState<any>([]);
  // 상담 이력
  const [history, setHistory] = useState<ChatSetProps[]>([]);

  useEffect(() => {
    // Component mount시 쿼리 스트링의 파라미터로 userState Setting(전역)
    if (props.userState.id === "") {
      const userInfo: userState = {
        id: queryParams.userId,
        name: queryParams.userName,
        imageUrl: queryParams.userImg,
        company: queryParams.userCompany,
      };
      props.setUserState(userInfo);
    }

    return () => {
      // unmount시 Hook 초기화(메모리)
      setIsLoading(false);
      setRecentSearch([]);
      setHistory([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 상담이력 조회 Trigger
  useEffect(() => {
    if (props.userState.id !== "") getHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userState]);

  // 상담이력 조회 Method
  const getHistory = async () => {
    const param: any = {
      command: "get_visachatset",
      id: props.userState.id + "#" + props.userState.company,
    };
    setIsLoading(true); // 로딩바 start
    const res = await mainApi.post(param);
    // api response 성공시
    if (res.code === "200") {
      // 핀고정 분류
      let pinList: any = [];
      let unPinList: any = [];
      for (const hisInfo of res.response.result_list) {
        if (hisInfo.IS_PIN) {
          pinList.push(hisInfo);
        } else {
          unPinList.push(hisInfo);
        }
      }
      // 핀고정 리스트 우선 노출 + 나머지
      setHistory([...pinList, ...unPinList]);
    }
    setIsLoading(false); // 로딩바 stop
  };

  //======================================================================================
  // 채팅 페이지 이동
  //======================================================================================
  const goChatPage = (code: string) => {
    navigate(`/chat/${code}`);
  };

  //======================================================================================
  // 공통 코드
  //======================================================================================
  // PIN 고정 해제
  const set_visa_chat_un_pin = async (agentName: string) => {
    const param: any = {
      command: "set_visa_chat_set_pin",
      user_id: props.userState.id + "#" + props.userState.company,
      country: agentName,
      is_pin: false, // 해제
    };

    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      getHistory();
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  //======================================================================================
  // 공통 코드
  //======================================================================================
  // 국가명으로 국가코드 가져오기 공통 함수
  const getCountryInfoByName = (name: string) => {
    let result: any = {};
    for (const flagInfo of countries) {
      if (flagInfo.label === name) {
        result = flagInfo;
      }
    }
    return result;
  };

  //======================================================================================
  // 최근 AI Agent 선택 리스트
  //======================================================================================
  // 최근 검색어 쿠키에서 불러오기
  const getRecentSearch = useCallback(() => {
    if (
      window.localStorage.getItem(`${process.env.REACT_APP_AI_RECENT_PREFIX}-${props.userState.id}`) !== null &&
      window.localStorage.getItem(`${process.env.REACT_APP_AI_RECENT_PREFIX}-${props.userState.id}`) !== ""
    ) {
      setRecentSearch(window.localStorage.getItem(`${process.env.REACT_APP_AI_RECENT_PREFIX}-${props.userState.id}`)?.split(","));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const makeRecentSearch = (data: any) => {
    // 최근 검색어 처리
    const dataText: string = `${data.code}/${data.label}/${data.englabel}`;
    let newRecents: any = [dataText, ...recentSearch];
    setRecentSearch(newRecents.slice(0, 3));
    window.localStorage.setItem(`${process.env.REACT_APP_AI_RECENT_PREFIX}-${props.userState.id}`, newRecents.slice(0, 3)); // 쿠키에 저장
  };

  // 최근 기록 삭제
  const deleteRecent = (index: number) => {
    let newRecents: any = [...recentSearch];
    newRecents.splice(index, 1);
    setRecentSearch(newRecents);
    window.localStorage.setItem(`${process.env.REACT_APP_AI_RECENT_PREFIX}-${props.userState.id}`, newRecents); // 쿠키에 저장
  };

  useEffect(() => {
    getRecentSearch();
  }, [getRecentSearch]);

  // Component (최근 검색 국가 표시하고 싶을때 이 컴포넌트를 배치할 것)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const RecentChips = () => {
    return (
      <Stack direction="row" spacing={1} className="recentChip">
        {recentSearch.map((agent: any, index: number) => (
          <Chip
            key={`recent-${index}`}
            label={agent.split("/")[1]}
            onClick={() => {
              goChatPage(agent.split("/")[0]);
            }}
            onDelete={() => {
              deleteRecent(index);
            }}
          />
        ))}
      </Stack>
    );
  };

  return (
    <>
      <div id="wrap">
        {/* 해더 */}
        <div id="header">
          <h1 className="logo">
            <img src="https://img.hyundaidreamtour.com/images/aihdt/mobile/m_aihdt_bi.svg" alt="현대드림투어 AI" />
          </h1>
        </div>
        {/* 본문 */}
        <div id="container">
          <div id="contents" className="aiChat">
            {/* AI 상담사 */}
            <div className="searchWrap">
              <div className="inner">
                {/* AI 상담사 콤보 컴포넌트*/}
                <ComboAiAgent
                  callback={(payload: any) => {
                    if (payload.command === "selected_ai_agent") {
                      // 최근 선택 리스트 업데이트
                      makeRecentSearch(payload.country);
                      // 채팅 페이지 이동
                      goChatPage(payload.country.code);
                    }
                  }}
                />
              </div>
            </div>
            {/* 상담이력 리스트 */}
            <div className="chatListCont">
              <div className="scroll noScrollBar">
                {/* 상담이력 없을때 */}
                {history.length === 0 && <div className="noList">상담 내역이 없습니다.</div>}
                {/* 상담이력 있을때 */}
                <ul className="chatList">
                  {history.map((row: any, index: number) => (
                    // 국가별 row
                    <li key={`chat-his-${index}`}>
                      <Box
                        className="goChat"
                        onClick={(e) => {
                          // PIN 이미지 클릭시에는
                          if (e.target === document.getElementById(`btnPin-${index}`)) {
                            set_visa_chat_un_pin(row.COUNTRY);
                            return;
                          }
                          const info = getCountryInfoByName(row.COUNTRY);
                          makeRecentSearch(info);
                          goChatPage(info.code);
                        }}
                      >
                        <p className="flag">
                          <img src={row.FLAG} alt={row.COUNTRY} />
                        </p>
                        <div className="aiCon">
                          <div className="aiInfo">
                            <span>{`AI ${row.COUNTRY} 상담사`}</span>
                            {row.IS_PIN && (
                              <span className="sticky">
                                <img
                                  id={`btnPin-${index}`}
                                  src="https://img.hyundaidreamtour.com/images/aihdt/mobile/m_ico_pin_blue.svg"
                                  alt="상담사 상단 고정"
                                />
                              </span>
                            )}
                            <span className="date">{row.RANGE}</span>
                          </div>
                          <p className="textPreview">{row.CHAT}</p>
                        </div>
                      </Box>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* 풋터 */}
        <FooterNavigation menuName={"aiCaht"} />
      </div>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default AiMain;
