import { useEffect, useState, forwardRef, useImperativeHandle } from "react";

interface propsType {
  scrollTo: any;
}

const ChatSearchNavigation = (props: propsType, ref: any) => {
  const [searchResultTagIds, setSearchResultTagIds] = useState<any>([]);
  const [nowSearchIndex, setNowSearchIndex] = useState(-1);

  useEffect(() => {
    return () => {
      setSearchResultTagIds([]);
      setNowSearchIndex(-1);
    };
  }, []);

  const setSearchResult = (list: any, index: number) => {
    setSearchResultTagIds(list);
    setNowSearchIndex(index);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    setSearchResult,
  }));
  return (
    <div id="footerFixed" className="moveArea">
      <div className="btnAreaRight">
        <button
          className={nowSearchIndex < 1 ? "btnUp disabled" : "btnUp"}
          onClick={() => {
            if (nowSearchIndex > 0) {
              const tagIndex = searchResultTagIds[nowSearchIndex - 1];
              setNowSearchIndex(nowSearchIndex - 1);
              const pos: any = document.getElementById(`chat-list-${tagIndex}`)?.offsetTop;
              props.scrollTo(pos);
              document.getElementById(`chat-list-${tagIndex}`)?.classList.add("blink");
              setTimeout(() => {
                document.getElementById(`chat-list-${tagIndex}`)?.classList.remove("blink");
              }, 1000);
            }
          }}
        >
          up
        </button>
        <button
          className={nowSearchIndex === searchResultTagIds.length - 1 ? "btnDown disabled" : "btnDown"}
          onClick={() => {
            if (nowSearchIndex < searchResultTagIds.length - 1) {
              const tagIndex = searchResultTagIds[nowSearchIndex + 1];
              setNowSearchIndex(nowSearchIndex + 1);
              const pos: any = document.getElementById(`chat-list-${tagIndex}`)?.offsetTop;
              props.scrollTo(pos);
              document.getElementById(`chat-list-${tagIndex}`)?.classList.add("blink");
              setTimeout(() => {
                document.getElementById(`chat-list-${tagIndex}`)?.classList.remove("blink");
              }, 1000);
            }
          }}
        >
          down
        </button>
      </div>
    </div>
  );
};

export default forwardRef(ChatSearchNavigation);
