import StopCircleIcon from "@mui/icons-material/StopCircle";

interface propsType {
  question: string;
  setQuestion: any;
  isAiLoading: boolean;
  userQuestion: any;
  stopAiAnswer: any;
  streamReader: any;
}

const ChatInput = (props: propsType) => {
  return (
    <div id="footerFixed" className="msgArea">
      <div className="sendMsg">
        <input
          className=""
          placeholder="AI 상담사에게 물어보세요."
          value={props.question}
          disabled={props.isAiLoading}
          onChange={(event) => {
            props.setQuestion(event.target.value);
          }}
          enterKeyHint="send"
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.nativeEvent.isComposing) {
              e.preventDefault();
              props.userQuestion();
            }
          }}
        />
        {/* <a className="sendFile"><img src="https://img.hyundaidreamtour.com/images/aihdt/mobile/m_ico_file.svg" alt="파일 전송" /></a> */}

        <button
          className={props.streamReader === null ? "" : "btn-ai_stop"}
          onClick={() => {
            if (props.streamReader === null) props.userQuestion();
            else props.stopAiAnswer();
          }}
        >
          {props.streamReader === null ? (
            <img src="https://img.hyundaidreamtour.com/images/aihdt/mobile/m_ico_sendmsg.svg" alt="메세지 보내기" />
          ) : (
            <StopCircleIcon sx={{ color: "#1D81A5" }} />
          )}
        </button>
      </div>
      <p className="caution">* 다양한 데이터 기반으로 AI가 답변을 생성하므로 부정확, 부적절한 정보가 포함될 수 있으며 민감한 개인 정보는 채팅창에 입력하지 마시기 바랍니다</p>
    </div>
  );
};

export default ChatInput;
