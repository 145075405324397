import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { Box } from "@mui/material";

import { userState, AcceptDocFiles, AcceptImageFiles } from "../../../interface/MainInterface";
import { CommonUtils } from "../../../utils/common_utils";

import AttachComponent from "../../modules/AttachComponent";

interface propsType {
  userState: userState;
  row: any;
  index: number;
  highlightText: string;
  scrollToBottom: any;
  callback: any;
}

const utils = new CommonUtils();

const UserMessage = (props: propsType) => {
  return (
    <div className="msg bot" key={props.index} id={`chat-list-${props.index}`}>
      <div className="msgTop">
        <span className="flag">
          <img src={props.row.FLAG} alt={props.row.COUNTRY} />
        </span>
        <span className="">AI {props.row.COUNTRY} 상담사</span>
      </div>
      <ReactMarkdown
        className="response"
        remarkPlugins={[remarkGfm]}
        components={{
          p: (props) => (
            <p
              {...props}
              style={{
                fontSize: "14px",
                margin: "0px",
              }}
            />
          ),
          strong: (props) => (
            <strong
              {...props}
              style={{
                fontSize: "14px",
                margin: "0px",
                color: "#ffffff",
                backgroundColor: "#000000",
              }}
            />
          ),
          li: (props) => (
            <li
              {...props}
              style={{
                fontSize: "14px",
              }}
            />
          ),
          table: (props) => (
            <table
              {...props}
              style={{
                borderCollapse: "collapse",
                width: "100%",
              }}
            />
          ),
          th: (props) => (
            <th
              {...props}
              style={{
                border: "1px solid #000000", // 원하는 선색으로 변경하세요.
                padding: "8px",
                textAlign: "left",
              }}
            />
          ),
          td: (props) => (
            <td
              {...props}
              style={{
                border: "1px solid #000000", // 원하는 선색으로 변경하세요.
                padding: "8px",
                textAlign: "left",
              }}
            />
          ),
          a: (props) => {
            const { href, children, ...restProps } = props;
            if (typeof href === "string") {
              const isURL = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(href);

              if (isURL) {
                const target = href.startsWith("http") ? "_blank" : "_self"; // Open external links in a new tab
                // Extract file extension from the URL
                const isFile = /\.(pdf|xls|xlsx|ppt|doc|jpg|png|gif)$/i.test(href);
                if (isFile) {
                  const fileExtensionMatch = href.match(/\.([0-9a-z]+)(?:[?#]|$)/i);
                  let fileExtension = fileExtensionMatch ? fileExtensionMatch[1].toLowerCase() : "";
                  if (fileExtension === "xlsx") {
                    fileExtension = "xls";
                  }

                  return (
                    <span
                      className={`attachFlie ${fileExtension}`}
                      style={{
                        lineHeight: "2",
                        backgroundColor: "transparent", // 배경색 없음
                      }}
                    >
                      <span style={{ lineHeight: "14px" }}>{`•`}</span>
                      <a
                        className="fileName"
                        href={href}
                        rel="noopener noreferrer"
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                          marginLeft: "10px", // 왼쪽 간격 추가
                          fontSize: "14px", // 글자 크기 조절
                          fontWeight: "400",
                        }}
                        {...restProps}
                      >
                        {children}
                      </a>
                    </span>
                  );
                } else {
                  const isUse = !/[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(href);
                  if (isUse) {
                    return (
                      <a
                        href={href}
                        target={target}
                        rel="noopener noreferrer"
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                        }}
                        {...restProps}
                      >
                        {children}
                      </a>
                    );
                  }
                }
              }
            }
            return <span {...restProps}>{children}</span>;
          },
        }}
      >
        {props.highlightText !== "" ? props.row.ANSWER.replaceAll(props.highlightText, `**${props.highlightText}**`) : props.row.ANSWER}
      </ReactMarkdown>
      {/* 첨부파일 영역 */}
      <Box
        sx={{ display: "flex", flexDirection: "row", gap: "6px", maxWidth: "calc(100% + 30px)", ml: "-15px", pl: "15px", pr: "15px" }}
        className="attachList noScrollBarX"
      >
        {props.row.EXTRA_LINKS?.length > 0 &&
          props.row.EXTRA_LINKS.map(
            (data: any, index: number) =>
              [...AcceptDocFiles, ...AcceptImageFiles].indexOf(data.type.toLowerCase()) > -1 && (
                <Box key={`extra-file-${index}`} sx={{ width: "144px" }}>
                  <AttachComponent data={data} mode={"chat"} callback={() => {}} />
                </Box>
              )
          )}
      </Box>
      {/* 좋아요, 싫어요 */}
      <div className="msgFooter">
        <span className="date">{utils.dateFormat(props.row.RANGE, "YYYYMMDDHHmmssSSS", "YYYY-MM-DD HH:mm")}</span>
        <button
          className={props.row.STATUS === "like" ? "like on" : "like"}
          onClick={() => {
            props.callback({ command: "like" });
          }}
        >
          좋아요
        </button>
        <button
          className={props.row.STATUS === "dislike" ? "dislike on" : "dislike"}
          onClick={() => {
            props.callback({ command: "dislike" });
          }}
        >
          싫어요
        </button>
      </div>
    </div>
  );
};

export default UserMessage;
