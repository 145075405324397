import { useNavigate } from "react-router-dom";

interface propsType {
  menuName: any;
}

const FooterNavigation = (props: propsType) => {
  let navigate = useNavigate();
  return (
    <div id="footerFixed">
      <ul className="gnb">
        {/* AI 채팅 메뉴 */}
        <li>
          <button
            className={props.menuName === "aiCaht" ? "mm chat on" : "mm chat"}
            style={{color: props.menuName === "aiCaht" ? "" : "black"}}
            onClick={() => {
              navigate("/aiVisa");
            }}
          >
            AI 채팅
          </button>
        </li>
        {/* FAQ 메뉴 */}
        <li>
          <button
            className={props.menuName === "faq" ? "mm faq on" : "mm faq"}
            style={{color: props.menuName === "faq" ? "" : "black"}}
            onClick={() => {
              navigate(`/faq`);
            }}
          >
            FAQ
          </button>
        </li>
        {/* QNA 메뉴 */}
        <li>
          <button
            className={props.menuName === "qna" ? "mm myqlist on" : "mm myqlist"}
            style={{color: props.menuName === "qna" ? "" : "black"}}
            onClick={() => {
              navigate(`/qna`);
            }}
          >
            문의하기
          </button>
        </li>
      </ul>
    </div>
  );
};

export default FooterNavigation;
