import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import dayjs from "dayjs";

// MUI
import { Box, Typography, Stack } from "@mui/material";

// APIs
import { HttpMainApi } from "../../interface/main-api";

// Common module
import { userState } from "../../interface/MainInterface";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";

interface propsType {
  userState: userState;
  callback: any;
}

const mainApi = new HttpMainApi(
  window.location.hostname === process.env.REACT_APP_PROD_URL
    ? `${process.env.REACT_APP_API_URL_PROD}/adminMain`
    : `${process.env.REACT_APP_API_URL_DEV}/adminMain`
);

const QnaList = (props: propsType, ref: any) => {
  const toastRef: any = useRef();

  // isTransaction
  const [isLoading, setIsLoading] = useState(false);

  // Qna list
  const [qnaList, setQnaList] = useState<any>([]);
  const [displayList, setDisplayList] = useState<any>([]);
  const [totalCnt, setTotalCnt] = useState(0);
  const [endCnt, setEndCnt] = useState(0);
  const [ingCnt, setIngCnt] = useState(0);

  // 현재 선택된 Qna SK
  const [selectedQnaKey, setSelectedQnaKey] = useState("");

  useEffect(() => {
    get_my_qna_list();
    // 메모리 소거
    return () => {
      setQnaList([]);
      setTotalCnt(0);
      setEndCnt(0);
      setIngCnt(0);
      setSelectedQnaKey("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 나의 문의내역 조회
  const get_my_qna_list = async () => {
    setSelectedQnaKey("");

    const param: any = {
      command: "get_my_qna_list",
      user_id: props.userState.id + "#" + props.userState.company,
      sortForward: false, // 정렬 false == sk 역순 정렬
    };

    setIsLoading(true);
    const res = await mainApi.post(param);
    // console.log("res : ", res);
    if (res.code === "200") {
      setQnaList(res.response.qna_list);
    } else {
      toastRef.current?.toast("지난 문의내역을 가져오지 못했습니다.\nERROR : " + res.response.error_msg, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setIsLoading(false);
  };

  // 통계데이터 생성
  useEffect(() => {
    if (qnaList.length > 0) {
      setTotalCnt(qnaList.length);
      let _endCnt: number = 0;
      let _ingCnt: number = 0;
      for (const qnaInfo of qnaList) {
        if (qnaInfo.answer_state === "N") _ingCnt = _ingCnt + 1;
        else _endCnt = _endCnt + 1;
      }

      setEndCnt(_endCnt);
      setIngCnt(_ingCnt);
    }

    if (selectedQnaKey === "") {
      setDisplayList([...qnaList]);
    } else {
      // console.log("111");
      let temp: any = [];
      for (const info of qnaList) {
        if (info.sk === selectedQnaKey) temp.push(info);
      }
      setDisplayList([...temp]);
    }
  }, [qnaList, selectedQnaKey]);

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    get_my_qna_list,
    setSelectedQnaKey,
  }));

  return (
    <>
      <Stack direction={"column"} spacing={3} sx={{ p: 2 }}>
        {/* Count */}
        {selectedQnaKey === "" && (
          <Box>
            <Stack direction={"row"} spacing={2}>
              <Stack direction={"row"} spacing={1}>
                <Typography sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "1", color: "#666666" }}>{`Total`}</Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: "700", lineHeight: "1", color: "#000000" }}>{totalCnt}</Typography>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Typography sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "1", color: "#666666" }}>{`답변완료`}</Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: "700", lineHeight: "1", color: "#000000" }}>{endCnt}</Typography>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Typography sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "1", color: "#666666" }}>{`답변대기`}</Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: "700", lineHeight: "1", color: "#000000" }}>{ingCnt}</Typography>
              </Stack>
            </Stack>
          </Box>
        )}

        {/* List */}
        <Box sx={{ width: "100%" }}>
          <Stack direction={"column"} spacing={3} sx={{ width: "100%" }}>
            {displayList.length === 0 && (
              <Typography
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50.4%, -50.4%)",
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: "400",
                  color: "#8A8A8A",
                }}
              >
                {"등록된 문의가 없습니다."}
              </Typography>
            )}
            {displayList.map((qnaInfo: any, index: number) => (
              // 답변 항목 Wrapper
              <Box key={`qna-list-${index}`} sx={{ width: "100%" }}>
                <Stack direction={"column"} spacing={1}>
                  {selectedQnaKey !== "" && (
                    <Stack className="formTable" sx={{ marginTop: '0 !important', marginBottom: '18px !important' }}>
                      <Stack className="formD" sx={{ marginTop: '0 !important' }}>
                        <Box>
                          <Typography sx={{ fontWeight: "500", fontSize: "14px", lineHeight: "14px", color: "#666666" }}>
                            <label className="formTit required" htmlFor="myQTit">{`제목`}</label>
                          </Typography>
                        </Box>
                        <Box sx={{ border: "1px solid #333333", borderRadius: "8px", backgroundColor: "#f6f6f6", p: "9px" }}>
                          <Typography sx={{ fontWeight: "400", fontSize: "14px", lineHeight: "20px", color: "#000000" }}>
                            {qnaInfo.title}
                          </Typography>
                        </Box>
                      </Stack>
                    </Stack>
                  )}
                  <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    {/* 답변완료 icon, 카테고리 */}
                    <Stack direction={"row"} spacing={1} sx={{ alignItems: "center" }}>
                      <Box
                        sx={{
                          borderRadius: "4px",
                          p: "4px 8px 4px 8px",
                          ...(qnaInfo.answer_state === "N" ? { backgroundColor: "#B60000" } : { backgroundColor: "#1D81A5" }),
                        }}
                      >
                        <Typography sx={{ fontSize: "12px", fontWeight: "400", lineHeight: "12px", color: "#ffffff" }}>
                          {qnaInfo.answer_state === "N" ? "답변대기" : "답변완료"}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "14px", color: "#000000" }}>
                          {qnaInfo.category}
                        </Typography>
                      </Box>
                    </Stack>
                    {/* 등록시간 */}
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ fontSize: "12px", fontWeight: "400", lineHeight: "14px", color: "#8A8A8A" }}>
                        {dayjs(qnaInfo.myask_visa_sk).format("YYYY.MM.DD")}
                      </Typography>
                    </Box>
                  </Box>
                  {/* 사용자 문의하기 내용 */}
                  <Box
                    sx={{
                      borderRadius: "6px",
                      p: "6px 12px",
                      ...(selectedQnaKey === "" ? { border: "1px solid #DDDDDD" } : { border: "1px solid #333333", backgroundColor: "#f6f6f6" }),
                    }}
                    onClick={() => {
                      // 현재 선택된 문의하기 key update
                      setSelectedQnaKey(qnaInfo.sk);
                      props.callback({ command: "view_detail", qnaInfo: qnaInfo });
                    }}
                  >
                    <Typography className={selectedQnaKey === "" ? "qna-content-txt ellipsis-2line" : "qna-content-txt"} sx={{ ...(selectedQnaKey === "" ? {} : {...(qnaInfo.answer_state !== "N" ? {height: "202px"} : {height: "384px"})}), }} >
                      {selectedQnaKey === "" ? qnaInfo.title : qnaInfo.content}
                    </Typography>
                  </Box>
                  {/* 답변완료 클릭시 답변 목록 보여주기 */}
                  {selectedQnaKey !== "" && qnaInfo.answer_state !== "N" && (
                    <>
                      <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", marginTop: '20px !important' }}>
                        {/* 답변완료 icon, 카테고리 */}
                        <Stack direction={"row"} spacing={1} sx={{ alignItems: "center" }}>
                          <Box>
                            <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "14px", color: "#000000" }}>
                              {"현대드림투어 담당자"}
                            </Typography>
                          </Box>
                        </Stack>
                        {/* 등록시간 */}
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography sx={{ fontSize: "12px", fontWeight: "400", lineHeight: "14px", color: "#8A8A8A" }}>
                            {dayjs(qnaInfo.myask_visa_sk).format("YYYY.MM.DD")}
                          </Typography>
                        </Box>
                      </Box>
                      {/* 사용자 문의하기 내용 */}
                      <Box
                        sx={{
                          borderRadius: "6px",
                          p: "6px 12px",
                          ...(selectedQnaKey === "" ? { border: "1px solid #DDDDDD" } : { border: "1px solid #333333", backgroundColor: "#f6f6f6" }),
                        }}
                      >
                        <Typography className={selectedQnaKey === "" ? "qna-content-txt ellipsis-2line" : "qna-content-txt"} sx={{ ...(selectedQnaKey === "" ? {} : {height: "202px"}), }}>
                          {qnaInfo.answer}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Stack>
              </Box>
            ))}
          </Stack>
        </Box>
      </Stack>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default forwardRef(QnaList);
