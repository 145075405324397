import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { userState } from "../../interface/MainInterface";

interface propsType {
  userState: userState;
  isSearchMode: boolean;
  setIsSearchMode: any;
  agentName: string;
  isPin: boolean;
  callback: any;
}

const ChatHeader = (props: propsType) => {
  let navigate = useNavigate();

  // 검색어
  const searchTextRef: any = useRef(); // setFocus를 위해 ref 사용
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    return () => {
      setSearchText("");
    };
  }, []);

  return (
    <div id="header">
      {/* 기본 해더 */}
      <button
        className="btnPrev"
        onClick={() => {
          navigate("/aiVisa");
        }}
      >
        이전 페이지로 이동
      </button>
      <h1>{`AI ${props.agentName} 상담사`}</h1>
      <div className="btnAreaRight">
        {/* 핀고정 */}
        <button
          className={props.isPin ? "btnPin on" : "btnPin"}
          onClick={() => {
            props.callback({ command: "pin", agentName: props.agentName });
          }}
        >
          핀고정
        </button>
        {/* 검색 시작 */}
        <button
          className="btnSearch searchToggle"
          onClick={() => {
            // 부모 Component 검색 모드 : 하단 검색 네비게이션으로 교체
            props.setIsSearchMode(true);
            setSearchText("");
            searchTextRef.current.focus();
          }}
        >
          {" "}
        </button>
        {/* 파일함 버튼 */}
        <button
          className="btnMenu canvasToggle"
          onClick={(e) => {
            e.preventDefault();
            props.callback({ command: "open_attachs" });
          }}
        >
          파일/이미지 리스트 보기
        </button>
      </div>
      {/* 검색 모드 */}
      <div className={props.isSearchMode ? "searchChatWrap on" : "searchChatWrap"}>
        <div className="searchChat">
          <input
            className=""
            placeholder="상담내역 검색"
            ref={searchTextRef}
            value={searchText}
            type="search"
            enterKeyHint="search"
            onChange={(event) => {
              setSearchText(event.target.value);
              if (event.target.value === "") {
                // 검색어가 없는 경우 기존 검색 기록 초기화
                props.callback({ command: "search_close" });
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.nativeEvent.isComposing) {
                e.preventDefault();
                // 모바일에서 검색 후 키보드 닫기위해 focus blur
                searchTextRef.current.blur();
                // 부모 Component 검색 시작
                props.callback({ command: "search_text", text: searchText });
              }
            }}
          />
          {/* 검색 버튼 */}
          <button
            className="btnSearch"
            onClick={(e) => {
              e.preventDefault();
              searchTextRef.current.blur();
              props.callback({ command: "search_text", text: searchText });
            }}
          >
            <img src="https://img.hyundaidreamtour.com/images/aihdt/mobile/m_ico_search_black.svg" alt="검색" />
          </button>
        </div>
        {/* 취소 버튼 */}
        <button
          className="btnCancel searchChatClose"
          onClick={() => {
            props.setIsSearchMode(false);
            props.callback({ command: "search_close" });
            setSearchText("");
          }}
        >
          취소
        </button>
      </div>
    </div>
  );
};

export default ChatHeader;
