import { useEffect, useRef, useState } from "react";

// MUI
import { Box, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { grey } from '@mui/material/colors';

// APIs
import { HttpMainApi } from "../../interface/main-api";
import { HttpCustomApi } from "../../interface/custom-api";

// Common module
import { userState, FaqProps } from "../../interface/MainInterface";
import { useWindowSize } from "../../utils/useWindowSize";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";

import FaqHeader from "./FaqHeader";
import FooterNavigation from "../../mainContainer/FooterNavigation";

interface propsType {
  userState: userState;
}

const mainApi = new HttpMainApi(
  window.location.hostname === process.env.REACT_APP_PROD_URL
    ? `${process.env.REACT_APP_API_URL_PROD}/adminMain`
    : `${process.env.REACT_APP_API_URL_DEV}/adminMain`
);
const vectoCoreApi = new HttpCustomApi(process.env.REACT_APP_VECTOR_CORE);

const _MaxRow: number = 5;

const Faq = (props: propsType) => {
  const windowSize = useWindowSize();
  const toastRef: any = useRef();
  const [totalText, setTotalText] = useState('');
  const [message, setMessage] = useState('');

  // isTransaction
  const [isLoading, setIsLoading] = useState(false);

  // Faq list
  const [faqList, setFaqList] = useState<FaqProps[]>([]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  useEffect(() => {
    get_faq_list_ddb(); //초기 설정 FAQ 출력
    // 메모리 소거
    return () => {
      setIsLoading(false);
    };
  }, []);

  const get_faq_list_ddb = async () => {
    const param: any = {
      command: "get_information",
      pk: "faq#visa",
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      setFaqList(res.response.result_list);
    } else if (res.code === "300") {
      console.error(" 300 res.response : ", res.response);
    } else if (res.code === "500") {
      console.error(" 500 res.response : ", res.response);
    }

    setTotalText(`자주 묻는 질문`);
  };

  const search_faq_vectordb = async () => {
    // console.log('Send message:', message);
    setIsLoading(true);
    const xsearchparam: any = {
        command: "extream_search",      // xsearch
        index_name: "naverx-dreamtour-faq", // 벡터DB의 index명
        question: message, // 사용자 질문
        max_count: 5, // 검색결과 최대 수량
    };

    const xseres = await vectoCoreApi.auth_request(
        "api",
        xsearchparam,
        process.env.REACT_APP_TENANT_KEY
    );

    let tempRows = [];
    if (xseres.code === "200") {
      // console.log("xseres : ", xseres);
      for (let i of xseres['response']['result']) {
        let tempData: FaqProps;
        tempData = {
          'pk': ""
          , 'sk': ""
          , 'title': i['질문']
          , 'answer': i['답변']
        };
        tempRows.push(tempData);
      }
    }
    else {
      let tempData: FaqProps;
      tempData = {
        'pk': ""
        , 'sk': ""
        , 'title': "질문 답변 실패"
        , 'answer': "문제가 계속될 경우 관리자에게 문의 부탁드립니다."
      };
      tempRows.push(tempData);
    }
    console.log(tempRows);
    setTotalText(`문의하신 질문의 답변`);
    setFaqList(tempRows);
    setIsLoading(false);
  };

  function FaqRow(props: { faqInfo: ReturnType<any> }) {
    const { faqInfo } = props;
    const [open, setOpen] = useState(false);
    return (
      <Box sx={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)"}}>
        <Box sx={{ width: "100%", border: "1px solid #0C6A8D", ...(open ? { borderRadius: "8px 8px 0px 0px" } : { borderRadius: "8px" }) }}>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row", p: 1.4, alignItems: "center" }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "1.5", // 줄간격을 1.5로 설정
              }}
            >
              {` ${faqInfo.title}`}
            </Typography>
            <Box sx={{ ml: 1 }}>{open ? <ExpandLessIcon sx={{ color: grey[500] }} /> : <ExpandMoreIcon sx={{ color: grey[500] }} />}</Box>
          </Box>
        </Box>
        {open ? (
          <Box
            sx={{
              width: "100%",
              border: "1px solid #ddd",
              backgroundColor: "#f6f6f6",
              ...(open ? { borderRadius: "0px 0px 6px 6px" } : { borderRadius: "6px" }),
            }}
          >
            <Box sx={{ p: 1.4 }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  whiteSpace: "pre-wrap",
                  lineHeight: "1.5", // 줄간격을 1.5로 설정
                }}
              >
                {faqInfo.answer}
              </Typography>
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ width: "100%", height: `calc(${windowSize.height}px - 0px)`, backgroundColor: "#ffffff" }}>
        {/* 헤더 */}
        <Box sx={{ backgroundColor: "#ffffff", width: "100%", zIndex: "200" }}>
          <FaqHeader userState={props.userState} />
        </Box>
        {/* FAQ Content */}
        <div className="searchWrap">
          <div className="inner">
            <div className="search">
              <input
                style={{ color: "black" }}  // 글자색을 검정으로 설정
                placeholder="Message 현대드림투어 AI"
                value={message}
                disabled={isLoading}
                onChange={handleInputChange}
                enterKeyHint="send"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.nativeEvent.isComposing) {
                    e.preventDefault();
                    search_faq_vectordb();
                  }
                }}
              />
              <button
                className="sendText"
                disabled={isLoading}
                onClick={search_faq_vectordb}
              >
                <img src="https://img.hyundaidreamtour.com/images/aihdt/mobile/m_ico_sendmsg.svg" alt="메세지 보내기" />
              </button>
            </div>
          </div>
        </div>	
        <Box sx={{ height: `calc(${windowSize.height}px - 64px - 66px - 57px)` }} className="noScrollBar">
          <Box sx={{ p: 2 }}>
            <Stack direction={"column"} spacing={1}>
              {/* Count */}
              <Stack direction={"row"} spacing={1}>
                <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "14px", color: "#666666" }}>{totalText}</Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "14px", color: "#000000" }}>{`Top `}</Typography>
                <Typography sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "14px", color: "#000000" }}>{faqList.length}</Typography>
              </Stack>
              {/* FAQ list */}
              {faqList.map((faqInfo: FaqProps, index: number) => index < _MaxRow && <FaqRow key={`faq-row-${index}`} faqInfo={faqInfo} />)}
            </Stack>
          </Box>
        </Box>
        {/* <Box sx={{ height: `calc(${windowSize.height}px - 64px - 66px)` }} className="noScrollBar"> */}
          {/* <Box sx={{ p: 2 }}> */}
            {/* <Stack direction={"column"} spacing={1}> */}
              {/* Count */}
              {/* <Stack direction={"row"} spacing={1}> */}
                {/* <Typography sx={{ fontSize: "12px", fontWeight: "500", lineHeight: "14px", color: "#666666" }}>{`Total`}</Typography> */}
                {/* <Typography sx={{ fontSize: "12px", fontWeight: "500", lineHeight: "14px", color: "#000000" }}>{faqList.length}</Typography> */}
              {/* </Stack> */}
              {/* FAQ list */}
              {/* {faqList.map((faqInfo: FaqProps, index: number) => index < _MaxRow && <FaqRow key={`faq-row-${index}`} faqInfo={faqInfo} />)} */}
            {/* </Stack> */}
          {/* </Box> */}
        {/* </Box> */}

        {/* 풋터 */}
        <Box sx={{ backgroundColor: "#F5F7F9", width: "100%", zIndex: "200" }}>
          <FooterNavigation menuName={"faq"} />
        </Box>
      </Box>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default Faq;
