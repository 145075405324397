import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import dayjs from "dayjs";

import { Drawer, Box, Stack, Tabs, Tab, Grid } from "@mui/material";

import { AcceptDocFiles, AcceptImageFiles } from "../../interface/MainInterface";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { useWindowSize } from "../../utils/useWindowSize";

import { HttpMainApi } from "../../interface/main-api";

import AttachComponent from "../modules/AttachComponent";

interface propsType {
  callback: any;
}

const mainApi = new HttpMainApi(
  window.location.hostname === process.env.REACT_APP_PROD_URL
    ? `${process.env.REACT_APP_API_URL_PROD}/adminMain`
    : `${process.env.REACT_APP_API_URL_DEV}/adminMain`
);
const DrawerAttachs = (props: propsType, ref: any) => {
  const toastRef: any = useRef();
  const windowSize = useWindowSize();

  const [isLoading, setIsLoading] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);

  // Tab
  const [tabValue, setTabValue] = useState("basic");

  const [basicAttachs, setBasicAttachs] = useState<any>([]);
  const [attachs, setAttachs] = useState<any>({}); // 첨부파일 목록
  const [docAttachs, setDocAttachs] = useState<any>([]);
  const [imageAttachs, setImageAttachs] = useState<any>([]);

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setOpenDrawer(false);
      setTabValue("basic");
      setBasicAttachs([]);
      setAttachs({});
    };
  }, []);

  const open = (id: string, agentName: string, visaBasicInfo: any, company: string) => {
    // 과거 AI 답변에서 나온 파일을 조회
    getChatAttachs(id, agentName, company);

    // 국가별 비자 기본정보에 등록된 첨부파일을 format에 맞게 convert
    if (visaBasicInfo.ATTACH_FILES.length > 0) {
      let basicList: any = [];
      for (const info of visaBasicInfo.ATTACH_FILES) {
        // console.log("info : ", info);
        const basicData: any = {
          link: info.DOWNLOAD_URL,
          timestamp: "",
          title: info.FILE_NAME,
          type: info.FILE_EXT,
          isS3: true,
          path: info.FILE_PATH,
        };
        basicList.push(basicData);
      }
      setBasicAttachs(basicList);
    }

    // open
    setOpenDrawer(true);
  };

  const close = () => {
    setAttachs({});
    setBasicAttachs([]);
    setOpenDrawer(false);
  };

  const getChatAttachs = async (id: string, agentName: string, company: string) => {
    const param: any = {
      command: "get_visa_chat_attachs",
      user_id: id + "#" + company, // 사용자 ID 자리 수정 필요
      agent_name: agentName,
      scan_forward: false, // 최신순으로 가져오기
    };

    // setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      const datas: any = res.response.attachs;
      for (const date of Object.keys(datas)) {
        for (const info of datas[date]) {
          if (AcceptDocFiles.indexOf(info.type.toLowerCase()) > -1) {
            setDocAttachs([...docAttachs, info]);
          }
          if (AcceptImageFiles.indexOf(info.type.toLowerCase()) > -1) {
            setImageAttachs([...imageAttachs, info]);
          }
        }
      }
      setAttachs(res.response.attachs);
    }
    // setIsLoading(false);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    getChatAttachs,
    open,
  }));

  return (
    <>
      <Drawer anchor={"right"} open={openDrawer} onClose={close} PaperProps={{ sx: { width: "100%", height: "100%" } }}>
        <Stack direction={"column"} spacing={0} className="offCanvas">
          {/* 해더 */}
          <div className="canvasHeader">
            <button className="btnPrev" onClick={close}>
              채팅창으로 이동
            </button>
            <h2>파일함</h2>
            <div className="btnAreaRight">
              <button className="btnClose" title="close" onClick={close}>
                닫기
              </button>
            </div>
          </div>
          {/* Tab */}
          <Box>
            <Tabs
              value={tabValue}
              onChange={(event: React.SyntheticEvent, newValue: string) => {
                setTabValue(newValue);
              }}
              variant="fullWidth"
              scrollButtons={false}
              sx={{
                minHeight: "41px",
                "& .MuiTabs-indicator": {
                  height: "1px",
                  backgroundColor: "#222222",
                },
              }}
            >
              <Tab
                label="자료실"
                value="basic"
                disableRipple
                sx={{ p: "0px", borderBottom: 1, borderColor: "divider", "&.Mui-selected": { color: "#000000" } }}
              />
              <Tab
                label="파일"
                value="file"
                disableRipple
                sx={{ p: "0px", borderBottom: 1, borderColor: "divider", "&.Mui-selected": { color: "#000000" } }}
              />
              <Tab
                label="사진"
                value="pic"
                disableRipple
                sx={{ p: "0px", borderBottom: 1, borderColor: "divider", "&.Mui-selected": { color: "#000000" } }}
              />
            </Tabs>
          </Box>
          {/* 본문 */}
          <Box sx={{ p: "24px 15px", height: `calc(${windowSize.height}px - 112px)` }} className="noScrollBar">
            {/* 자료실 */}
            {basicAttachs.length === 0 && <div className="noList">등록된 파일이 없습니다.</div>}
            <Grid container spacing={0.75} sx={{ ...(tabValue === "basic" ? {} : { display: "none" }) }}>
              {basicAttachs.map(
                (data: any, attachIndex: number) =>
                  AcceptDocFiles.indexOf(data.type.toLowerCase()) > -1 && (
                    <Grid key={`attach-file-${attachIndex}`} item xs={4} sm={3} md={2} lg={2} xl={1}>
                      <AttachComponent data={data} mode={"box"} callback={() => {}} />
                    </Grid>
                  )
              )}
            </Grid>
            {/* 파일 */}
            <Stack className="fileBox" direction={"column"} spacing={3} sx={{ ...(tabValue === "file" ? {} : { display: "none" }) }}>
              {docAttachs.length === 0 && <div className="noList">등록된 파일이 없습니다.</div>}
              {Object.keys(attachs).map((date: string, index: number) => (
                <Box key={`attachs-root-${index}`} className="fileGroup">
                  <Stack direction={"column"} spacing={0}>
                    <span className="upDate">{dayjs(date).format("YYYY.MM.DD")}</span>
                    <Box>
                      <Grid container spacing={0.75}>
                        {attachs[date].map(
                          (data: any, attachIndex: number) =>
                            AcceptDocFiles.indexOf(data.type.toLowerCase()) > -1 && (
                              <Grid key={`attach-file-${attachIndex}`} item xs={4} sm={3} md={2} lg={2} xl={1}>
                                <AttachComponent data={data} mode={"box"} callback={() => {}} />
                              </Grid>
                            )
                        )}
                      </Grid>
                    </Box>
                  </Stack>
                </Box>
              ))}
            </Stack>
            {/* 이미지 */}
            <Stack className="fileBox" direction={"column"} spacing={3} sx={{ ...(tabValue === "pic" ? {} : { display: "none" }) }}>
              {imageAttachs.length === 0 && <div className="noList">등록된 파일이 없습니다.</div>}
              {Object.keys(attachs).map((date: string, index: number) => (
                <Box key={`attachs-root-${index}`} className="fileGroup">
                  <Stack direction={"column"} spacing={0}>
                    <span className="upDate">{dayjs(date).format("YYYY.MM.DD")}</span>
                    <Box>
                      <Grid container spacing={0.75}>
                        {attachs[date].map(
                          (data: any, attachIndex: number) =>
                            AcceptImageFiles.indexOf(data.type.toLowerCase()) > -1 && (
                              <Grid key={`attach-file-${attachIndex}`} item xs={4} sm={3} md={2} lg={2} xl={1}>
                                <AttachComponent data={data} mode={"box"} callback={() => {}} />
                              </Grid>
                            )
                        )}
                      </Grid>
                    </Box>
                  </Stack>
                </Box>
              ))}
            </Stack>
          </Box>
        </Stack>
      </Drawer>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default forwardRef(DrawerAttachs);
