import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";

import { Container, CssBaseline, Box } from '@mui/material';
import { ToastContainer, toast, TypeOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import Hex from 'crypto-js/enc-hex';

// APIs
import { HttpMainApi } from "../../interface/main-api";

const mainApi = new HttpMainApi(
  window.location.hostname === process.env.REACT_APP_PROD_URL
    ? `${process.env.REACT_APP_API_URL_PROD}/adminMain`
    : `${process.env.REACT_APP_API_URL_DEV}/adminMain`
);

const DreamtourTestIFrame = () => {
  const [userCode, setUserCode] = useState("");
  const [siteName, setSiteName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const showToast = (message: string, status: TypeOptions, ms: number) => {
    toast(message, { type: status, autoClose: ms, position: "top-center" }); // 필요에 따라 autoClose 기간을 조정하세요
  };

  // AES-256로 암호화된 문자열을 복호화하는 함수
  const getUserKey = (encryptedText: string): string => {
    // console.log(`${process.env.REACT_APP_AES_KEY}`, `${process.env.REACT_APP_AES_IV}`);
    const bytes = AES.decrypt(encryptedText, Hex.parse(`${process.env.REACT_APP_AES_KEY}`), { iv: Hex.parse(`${process.env.REACT_APP_AES_IV}`) });
    let decryptedString;
    try {
        decryptedString = bytes.toString(Utf8);
    } catch (error) {
      showToast("인증키를 확인해 주세요.", "warning", 3000);
      console.error("Error while decoding UTF-8:", error);
      decryptedString = ''; // 빈 문자열 또는 다른 기본값으로 설정할 수 있습니다.
    }
    return decryptedString;
  };
  
  const fncProcLogin = async () => {
    let param : any = "";
    let res : any = "";

    if (userName !== "") {
      param = {
        command: "put_user",
        pk: companyName,
        sk: userId,
        name: userName,
        image_url: "",
        user_info1: companyName,
        user_info2: companyName,
        user_info3: companyName,
        user_info4: companyName,
        user_type: 3,
        site_name: siteName,
      }
      res = await mainApi.post(param);
      if (res.code !== "200") {
        showToast(res.response.result_msg, "error", 3000);
      }
    }

    param = {
      command: "login_proc",
      pk: companyName,
      sk: userId,
    } 

    res = await mainApi.post(param);
    if (res.code === "200") {
      if (res.response.result === "success") procSesson(res.response.session);
      else procFail(res.response);
    } else {
      showToast(res.response.result_msg, "error", 3000);
    }
  };

  // 로그인 가능 시간 계산
  function checkKeyValid(dateTime: string): boolean {
    // 주어진 문자열에서 연, 월, 일, 시, 분, 초를 추출
    const year = parseInt(dateTime.substring(0, 4));
    const month = parseInt(dateTime.substring(4, 6)) - 1; // JavaScript Date 객체는 월을 0부터 시작하므로 1을 빼줍니다.
    const date = parseInt(dateTime.substring(6, 8));
    const hours = parseInt(dateTime.substring(8, 10));
    const minutes = parseInt(dateTime.substring(10, 12));
    const seconds = parseInt(dateTime.substring(12, 14));
  
    // 주어진 문자열을 Date 객체로 변환
    const targetTime = new Date(year, month, date, hours, minutes, seconds);
  
    // 현재 시간을 가져와서 차이를 계산
    const now = new Date();
    const differenceInSeconds = Math.abs((now.getTime() - targetTime.getTime()) / 1000);
    const differenceInMinutes = differenceInSeconds / 60;
    
    return differenceInMinutes > 5; // 5분 보다 크면 true, 로그인 불가능
  }

  // rds 통신해서 람다 호출
  const check_user_db = async (key_id: string) => {
    let dateTime: string = ""; // "20240325161126"
    let siteName: string = ""; // "hdream"
    let companyName: string = ""; // "현대아이티앤이"
    let userId: string ="";
    let userName: string =""; // "2014748"
    let validFlag: boolean = false;

    if(key_id === "1724621" || key_id === "2219217" || key_id === "2014748"
        || key_id === "1727822" || key_id === "0900095") {//admin은 h드림 안통하고 바로 로그인가능하도록 처리
      userId = key_id;
      companyName = "현대드림투어";
    }
    else {
      const parts: string[] = key_id.split(":");
      dateTime = parts[0]; // "20240325161126"
      siteName = parts[1]; // "Hbiz"
      companyName = parts[2]; // "현대아이티앤이"
      userId = parts[3]; // "2014748"
      if ( parts.length > 4 ) {
        userName = parts[4]; // "이민호"
      }
      validFlag = checkKeyValid(dateTime);
  
      if(validFlag) {
        showToast("유효하지 않는 접근입니다.", "warning", 3000);
        return;
      }
    }
    setSiteName(siteName);
    setCompanyName(companyName);
    setUserId(userId);
    setUserName(userName);
  };

  // 로그인 성공
  const procSesson = (sessionData: any) => {
    showToast("Welcome. Enjoy 드림투어AI", "success", 1000);
    const code = `<iframe src="/aiVisa/?userId=${userId}&userName=${sessionData.user_name}&userImg=${sessionData.image_url}&userCompany=${companyName}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="test"></iframe>`;
    setUserCode(code);
  };

  // 로그인 실패
  const procFail = (res: any) => {
    showToast(res.result_msg, "error", 3000);
    setUserCode("");
  };

  // url 가져오기
  const stateInfo: URLSearchParams = new URLSearchParams(
    window.location.search
  );

  useEffect(() => {
    if (userId !== "") {
      fncProcLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  //http://localhost:3000/login?requestKey=2014748
  //http://localhost:3000/?requestKey=2014748
  //http://localhost:3000/login?requestKey=2024062510190000:Hbiz:현대아이티앤이:2014748:이민호
  useEffect(() => {
    window.history.replaceState({}, "", window.location.pathname);
    const requestKey = stateInfo.get('requestKey');

    if (requestKey) {
      if(requestKey === "1724621" || requestKey === "2219217" || requestKey === "2014748"
        || requestKey === "1727822" || requestKey === "0900095"){
        check_user_db(requestKey);
      }else{
        const decodedRequestKey = requestKey.replace(/\s/g, '+');
        // console.log("decodedRequestKey:", decodedRequestKey);
        const keyInfo: string = decodedRequestKey;
        const customKey = getUserKey(keyInfo);
        check_user_db(customKey);
      }
    }
    else {
      console.log("인증키를 확인해 주세요.");
      showToast("인증키를 확인해 주세요.", "error", 3000);
    }

    // 0.1초 후에 isLoading을 true로 설정
    const timeout = setTimeout(() => {
      setIsLoading(true);
    }, 1000);

    // 컴포넌트가 언마운트되기 전에 타임아웃을 제거하여, 타임아웃이 발생하기 전에 컴포넌트가 사라지면 isLoading을 true로 설정하지 않도록 합니다.
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ToastContainer />
      {userCode ? (
        <Box sx={{ p: 0, width: "100%", height: "100%" }}>
          <Box dangerouslySetInnerHTML={{ __html: userCode }} />
        </Box>
      ) : (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h2>
              <img src="https://img.hyundaidreamtour.com/images/aihdt/loading_ai.svg" alt="logo" />
            </h2>
            {isLoading === true ? (
              <Stack spacing={2} direction="column"
                sx={{
                  marginTop: "50px",
                  alignItems: "center", // 요소들을 가운데 정렬
                }}
              >
                <p>현대드림투어AI에 오신걸 환영해요.</p>
                <div className="form-wrap">
                  {" "}
                  정상적인 방법으로 접속해주시기 바랍니다.
                </div>
              </Stack>
            ):(
              <Stack spacing={6} direction="column"
                sx={{
                  marginTop: "50px",
                }}
              >
                <p>{" "}</p>
                <div className="form-wrap">
                  {" "}
                  {" "}
                </div>
              </Stack>
            )}
          </Box>
        </Container>
      )}
    </>
  );
};

export default DreamtourTestIFrame;
