import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { Drawer, Box, Stack, Button } from "@mui/material";

import { userState, ChatCategory } from "../../interface/MainInterface";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";

import { HttpMainApi } from "../../interface/main-api";

interface propsType {
  userState: userState;
  agentName: string;
  callback: any;
}

const mainApi = new HttpMainApi(
  window.location.hostname === process.env.REACT_APP_PROD_URL
    ? `${process.env.REACT_APP_API_URL_PROD}/adminMain`
    : `${process.env.REACT_APP_API_URL_DEV}/adminMain`
);
const DrawerDislike = (props: propsType, ref: any) => {
  const toastRef: any = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);

  const [chatSk, setChatSk] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setOpenDrawer(false);
    };
  }, []);

  const open = useCallback((sk: string) => {
    setChatSk(sk);
    setSelectedCategory("");
    setContent("");
    setOpenDrawer(true);
  }, []);

  const close = () => {
    setOpenDrawer(false);
    setChatSk("");
    setSelectedCategory("");
    setContent("");
  };

  const saveVoc = async () => {
    if (selectedCategory === "") {
      toastRef.current?.toast("불만족 사유를 선택해주세요.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    const param: any = {
      command: "update_information_sk",
      pk: props.userState.id + "#" + props.userState.company + "#" + props.agentName, // pk
      range: chatSk, // sk
      update_items: {
        editor: props.userState.id,
        category: selectedCategory,
        contact: "",
        inquiry: content,
        status: "dislike",
      },
    };

    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      toastRef.current?.toast("의견을 제출하였습니다.", "info", 4000, { vertical: "top", horizontal: "center" });
      props.callback({ command: "finish_dislike_proc" });
      close();
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <>
      <Drawer anchor={"bottom"} open={openDrawer} onClose={close} PaperProps={{ sx: { width: "100%", height: "100%" } }}>
        <div className="popupFullD">
          <div className="popHeader">
            <button className="btnPrev" onClick={close}>
              채팅창으로 이동
            </button>
            <p className="popTit">개선사항을 적어주세요!</p>
            <div className="btnAreaRight">
              <button className="btnClose popFullClose" title="close" onClick={close}>
                닫기
              </button>
            </div>
          </div>
          <div className="popCont">
            <div className="scroll noScrollBar">
              <div className="inner">
                <div className="formTable">
                  <form className="formD" id="formD">
                    <div>
                      더 좋은 서비스를 제공하기 위하여 불만족 사유와 의견을 부탁드립니다.
                      <label className="formTit required"></label>
                      <span style={{marginLeft: "12px", color: "#b60000"}}>
                        필수 항목입니다.
                      </span>
                    </div>
                  </form>
                </div>
                <div className="formTable">
                  <form className="formD" id="formD">
                    <label className="formTit required">작성자</label>
                    <input id="writer" className="" type="text" name="name" value={props.userState.name} readOnly />
                  </form>

                  <form className="formD" id="problemForm">
                    <span className="formTit required">불만족 사유를 입력하세요</span>
                    <Box>
                      <Stack direction={"column"} spacing={1}>
                        {ChatCategory.map((data: string, index: number) => (
                          <Box key={`cate-${index}`}>
                            <Button
                              variant="contained"
                              disableElevation
                              sx={{
                                borderRadius: "25px",
                                color: "#ffffff",
                                fontSize: "14px",
                                fontWeight: "500",
                                p: "6px 20px",
                                ...(data !== selectedCategory ? { backgroundColor: "#B4B4B4" } : {}),
                              }}
                              onClick={() => {
                                setSelectedCategory(data);
                              }}
                            >
                              {data}
                            </Button>
                          </Box>
                        ))}
                      </Stack>
                    </Box>
                  </form>

                  <form className="formD">
                    <label className="formTit required">의견</label>
                    <span className="textCount">{`${content.length > 300 ? 300 : content.length} / 300`}</span>
                    <textarea
                      className="opinion myTextarea"
                      id="opinion"
                      maxLength={300}
                      required
                      value={content}
                      onChange={(e) => {
                        setContent(e.target.value);
                      }}
                    ></textarea>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="popBottom btnFixedArea">
            <button
              className={selectedCategory !== "" && content !== "" ? "btnFull btnActive" : "btnFull btnDisabled"}
              id="submitBtn"
              onClick={() => {
                if (selectedCategory !== "" && content !== "") saveVoc();
              }}
            >
              제출하기
            </button>
          </div>
        </div>
      </Drawer>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default forwardRef(DrawerDislike);
