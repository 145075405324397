import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

// APIs
import { HttpMainApi } from "../../interface/main-api";
import { HttpAiApi } from "../../interface/ai-api";

// Common module
import { userState, countries, ChatContentProps } from "../../interface/MainInterface";
import { CommonUtils } from "../../utils/common_utils";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";

// Page Compoent
import ChatHeader from "./ChatHeader";
import ChatList from "./ChatList";
import ChatInput from "./ChatInput";
import ChatSearchNavigation from "./ChatSearchNavigation";
import DrawerDislike from "./DrawerDislike";
import DrawerAttachs from "./DrawerAttachs";
import AlertNoMorePin from "../modules/AlertNoMorePin";

interface propsType {
  userState: userState;
}

// 운영/개발서버 api 분배
const mainApi = new HttpMainApi(
  window.location.hostname === process.env.REACT_APP_PROD_URL
    ? `${process.env.REACT_APP_API_URL_PROD}/adminMain`
    : `${process.env.REACT_APP_API_URL_DEV}/adminMain`
);

// AI용 API
const aiApi = new HttpAiApi();
// 공통모듈 유틸
const utils = new CommonUtils();

const Chat = (props: propsType) => {
  const params = useParams(); // url parameter

  const toastRef: any = useRef();

  // ref
  const chatListRef: any = useRef();
  const chatSearchNaviRef: any = useRef();
  const drawerDislikeRef: any = useRef();
  const drawerAttachsRef: any = useRef();
  const alertNoMorePinRef: any = useRef();

  // isTransaction
  const [isLoading, setIsLoading] = useState(false);
  const [isAiLoading, setIsAiLoading] = useState(false);

  // parameters
  const [agentCode, setAgentCode] = useState("");
  const [agentName, setAgentName] = useState("");
  const [userId, setUserId] = useState("");

  // 비자 기본정보
  const [visaBasicInfo, setVisaBasicInfo] = useState<any>(null);

  // 채팅이력
  const [chatHistory, setChatHistory] = useState<ChatContentProps[]>([]);
  const [needFirstMsg, setNeedFirstMsg] = useState(false); // 당일 최초 메시지 IF True THEN View Visa basic info

  // 질의 응답
  const [question, setQuestion] = useState(""); // 사용자 질문
  const [aiAnswer, setAiAnswer] = useState(""); // AI 답변

  // 상단 검색 기능 사용시
  const [isSearchMode, setIsSearchMode] = useState(false); // 검색모드

  // AI stream 답변 저장용 객체
  const [streamReader, setStreamReader] = useState<ReadableStreamDefaultReader | null>(null); // AI 답변 Stream 객체

  // PIN
  const [isPin, setIsPin] = useState(false);
  const [pinList, setPinList] = useState<any>([]);

  useEffect(() => {
    // 메모리 소거
    return () => {
      setIsLoading(false);
      setIsAiLoading(false);
      setAgentCode("");
      setAgentName("");
      setUserId("");
      setVisaBasicInfo(null);
      setChatHistory([]);
      setNeedFirstMsg(false);
      setQuestion("");
      setAiAnswer("");
      setIsSearchMode(false);
      setStreamReader(null);
      setIsPin(false);
      setPinList([]);
    };
  }, []);

  useEffect(() => {
    if (props.userState.id !== "")
      // URL Parameter setting
      initCode(params.code, props.userState.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userState.id]);

  // URL Parameter로 전달받은 값을 세팅
  const initCode = (code: any, id: any) => {
    setAgentCode(code !== undefined ? code : ""); // 국가코드
    setAgentName(getCountryName(code)); // 국가 한글명
    setUserId(id !== undefined ? id : ""); // 사용자ID
  };

  // 1. 비자 기본정보 가져오기
  useEffect(() => {
    // 랜더링 후 처리되어야하는 이슈로 userId state를 사용함(props.userState.id 대신)
    if (agentCode !== "" && userId !== "") {
      // 비자 기본정보 가져오기
      getVisaBasicInfo();
      getHistory();
      getVisaChatSetInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentCode, userId]);

  // 비자 기본정보 조회
  const getVisaBasicInfo = async () => {
    const param = {
      command: "get_visa_sk",
      pk: "entry#visa",
      sk: agentName,
    };

    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      const basicInfo: any = {
        COUNTRY: res.response.result[0]["country"],
        NO_VISA: res.response.result[0]["no_visa"],
        VISA_ARRIVAL: res.response.result[0]["visa_on_arrival"],
        BUSINESS_VISA: res.response.result[0]["business_visa"],
        TWOV_CONDITION: res.response.result[0]["twov_condition"],
        REMAIN_PASSPORT: res.response.result[0]["remain_valid_passport"],
        EMERGENCY_PASSPORT: res.response.result[0]["emergency_passport"],
        TRAVEL_CERTIFICATE: res.response.result[0]["travel_certificate"],
        ETC: res.response.result[0]["etc"],
        REMARK: res.response.result[0]["remark"],
        DETAIL: res.response.result[0]["detail_information"],
        DATA_SOURCE: res.response.result[0]["data_source"],
        ATTACHED: res.response.result[0]["attach_source"],
        RANGE: res.response.result[0]["range"],
        COUNTRY_INFO: res.response.result[0]["country_info"],
        NOTE: res.response.result[0]["note"],
        ATTACH_FILES: res.response.result[0]["attach_files"],
      };
      setVisaBasicInfo(basicInfo);
      // 채팅 인사말
      firstHelloMsg(basicInfo);
    } else {
      toastRef.current?.toast("비자 필수정보 로딩 오류...", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setIsLoading(false);
  };

  // 1-2. 비자 쳇셋 정보 조회
  const getVisaChatSetInfo = async () => {
    const param: any = {
      command: "get_visa_chat_set_info",
      user_id: userId + "#" + props.userState.company,
      country: getCountryName(agentCode),
    };
    const res = await mainApi.post(param);
    if (res.code === "200") {
      setIsPin(res.response.visa_chat_set_info.is_pin);
    }
  };

  const getHistory = async () => {
    const param: any = {
      command: "get_visachatset",
      id: props.userState.id + "#" + props.userState.company,
    };
    // setIsLoading(true);
    const res = await mainApi.post(param);

    if (res.code === "200") {
      // 핀고정 분류
      let list: any = [];

      for (const hisInfo of res.response.result_list) {
        if (hisInfo.IS_PIN) {
          list.push(hisInfo);
        }
      }

      setPinList([...list]);
    }
    // setIsLoading(false);
  };

  // 2. 채팅 시작 (인사말)
  const firstHelloMsg = async (basicInfo: any) => {
    const param: any = {
      command: "set_visachatset",
      id: userId + "#" + props.userState.company, // 사용자 ID 자리 수정 필요
      country: agentName,
      flag_url: utils.makeCtryFlagUrl(agentCode),
    };
    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      getChatHistory();
      setNeedFirstMsg(res.response.to_flag);
    }
    setIsLoading(false);
  };

  // 3. 지난 채팅 이력 조회
  const getChatHistory = async () => {
    const param: any = {
      command: "get_visachatcontent",
      id: userId + "#" + props.userState.company, // 사용자 ID 자리 수정 필요
      country: agentName,
      flag: utils.makeCtryFlagUrl(agentCode),
    };

    // setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      let list: any = [];
      for (let info of res.response.result_list) {
        const attachs: any = utils.makeExtraLinks(info.ANSWER);
        info.EXTRA_LINKS = attachs;
        list.push(info);
      }
      setChatHistory(list);
      drawerAttachsRef.current.getChatAttachs(props.userState.id, agentName, props.userState.company); // not await
    }
    // setIsLoading(false);
  };

  // 비자기본정보, 인사말 표시 후 비자 기본정보 여부 판단하기.
  useEffect(() => {
    if (needFirstMsg && visaBasicInfo && chatHistory.length > 0) {
      // 마지막 채팅 후 1일 경과시 [국가별 비자 기본정보 보여주기]
      showSimpleVisaInfo(visaBasicInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visaBasicInfo, chatHistory, needFirstMsg]);

  //========================================================================
  // GenAI
  //========================================================================
  // AI 답변 form 초기화
  const initAiAnswer = () => {
    setIsAiLoading(true);
    setAiAnswer("");
  };

  // AI 답변 스트림 중지
  const stopAiAnswer = () => {
    if (streamReader !== null) {
      streamReader.cancel();
      streamReader.releaseLock();
    }
    setStreamReader(null);
  };

  // AI 스트림 답변 처리
  const procAiAnswer = async (streamResponse: any) => {
    // Stream Response 처리
    if (!streamResponse) {
      return;
    }

    const reader = streamResponse?.getReader();
    const decoder = new TextDecoder();
    setStreamReader(reader);

    let done = false;
    let content = "";

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading; // 스트림 완료시 true
      const chunkValue = decoder.decode(value);
      content = content + chunkValue;
      content = content.replace(/<br>/g, "\n");
      setAiAnswer(content);
    }

    stopAiAnswer();

    return content;
  };

  // AI 답변 저장
  const saveAiAnswer = async (content: string, chatList: ChatContentProps[]) => {
    const now = dayjs().format("YYYYMMDDHHmmssSSS");

    const _attachs: any = utils.makeExtraLinks(content);

    const param: any = {
      command: "put_visachatcontent_answer",
      id: userId + "#" + props.userState.company, // 사용자 ID 자리 수정 필요
      country: agentName,
      flag_url: utils.makeCtryFlagUrl(agentCode),
      answer: content,
      currentTime: now, // 질문과 sk 겹침방지
      attachs: _attachs,
    };

    const res = await mainApi.post(param);
    // console.log("res : ", res);
    if (res.code === "200") {
      const newChat: ChatContentProps = {
        ID: userId, // 사용자 ID 자리 수정 필요
        COUNTRY: agentName,
        FLAG: utils.makeCtryFlagUrl(agentCode),
        RANGE: now,
        ANSWER: content,
        QUESTION: "",
        READ_FLAG: true,
      };
      
      const attachs: any = utils.makeExtraLinks(newChat.ANSWER);
      newChat.EXTRA_LINKS = attachs;
      let tempLiat: ChatContentProps[] = [...chatList, newChat];
      setChatHistory(tempLiat);

      drawerAttachsRef.current.getChatAttachs(props.userState.id, agentName, props.userState.company); // not await
    }
    setIsAiLoading(false);
  };

  // 사용자 질문 저장
  const saveUserQuestion = async () => {
    let tempList: ChatContentProps[] = [];
    const now = dayjs().format("YYYYMMDDHHmmssSSS");

    // 사용자 질문 저장
    const param: any = {
      command: "put_visachatcontent_question",
      id: userId + "#" + props.userState.company,
      country: agentName,
      flag_url: utils.makeCtryFlagUrl(agentCode),
      question: question,
      currentTime: now,
    };

    // console.log("param:", param);
    const res = await mainApi.post(param);
    // 채팅목록 추가
    if (res.code === "200") {
      // DB 다녀오면 화면 깜빡임과 같은 자연스럽지 못한 모습이 있어 직접 Data를 history에 넣어줌
      const newChat: ChatContentProps = {
        ID: userId, // 사용자 ID 자리 수정 필요
        COUNTRY: agentName,
        FLAG: utils.makeCtryFlagUrl(agentCode),
        RANGE: now,
        ANSWER: "",
        QUESTION: question,
        READ_FLAG: true,
      };

      tempList = [...chatHistory, newChat];
      setChatHistory((chatHistory) => tempList);

      setQuestion("");

      return tempList;
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, { vertical: "top", horizontal: "center" });
      return null;
    }
  };

  // 국가별 비자 기본정보 소스 생성
  const makeSimpleViasInfoSource = (basicInfo: any) => {
    const 국가명 = [basicInfo.COUNTRY];
    const 무비자입국A = [basicInfo.NO_VISA];
    const 무비자입국B = [basicInfo.VISA_ARRIVAL];
    const 상용비자 = [basicInfo.BUSINESS_VISA];
    const 통과여객조건 = [basicInfo.TWOV_CONDITION];
    const 여행증명서조건A = [basicInfo.REMAIN_PASSPORT];
    const 여행증명서조건B = [basicInfo.EMERGENCY_PASSPORT];
    const 여행증명서조건C = [basicInfo.TRAVEL_CERTIFICATE];
    const 여행증명서조건D = [basicInfo.ETC];
    const 유의사항 = [basicInfo.REMARK];
    const 구비서류 = [basicInfo.DETAIL];
    const 대사관주소 = [basicInfo.DATA_SOURCE];
    const 첨부파일출처 = [basicInfo.ATTACHED];
    const 국가정보 = [basicInfo.NOTE]; //비고란을 국가정보로 변경하였기 때문에 NOTE가 국가정보
    const 비고 = [basicInfo.COUNTRY_INFO]; //현재 COUNTRY_INFO를 비고로 사용 중

    let attach_contents: string = "";
    for (let file of basicInfo.ATTACH_FILES) {
      const downloadUrl: string = utils.genS3DownloadUrl(file);
      attach_contents += "첨부파일 이름: " + file.FILE_NAME + ", 첨부파일 다운로드 URL: " + downloadUrl + "\n";
    }

    let detail_contents: string = "";
    detail_contents += "1. 국가명: " + 국가명.join(", ") + "\n";
    detail_contents += "2. 일반 여권 소지자 비자 필요 여부: " + 무비자입국A.join(", ") + "\n";
    detail_contents += "3. 도착사증 발급 여부: " + 무비자입국B.join(", ") + "\n";
    detail_contents += "4. 상용비자: " + 상용비자.join(", ") + "\n";
    detail_contents += "5. 통과여객(TWOV)조건: " + 통과여객조건.join(", ") + "\n";
    detail_contents += "6. 여권 조건 (여권잔여유효기간): " + 여행증명서조건A.join(", ") + "\n";
    detail_contents += "7. 여권 조건 (긴급여권): " + 여행증명서조건B.join(", ") + "\n";
    detail_contents += "8. 여권 조건 (여행증명서): " + 여행증명서조건C.join(", ") + "\n";
    detail_contents += "9. 여권 조건 (기타): " + 여행증명서조건D.join(", ") + "\n";
    detail_contents += "10. 유의사항: " + 유의사항.join(", ") + "\n";
    detail_contents += "11. 비자 종류 및 구비서류 상세 정보: " + 구비서류.join(", ") + "\n";
    detail_contents += "12. 대사관 정보: " + 대사관주소.join(", ") + "\n";
    detail_contents += "13. 국가 정보: " + 국가정보.join(", ") + "\n";
    detail_contents += "14. 비고: " + 비고.join(", ") + "\n";
    detail_contents += "15. 첨부파일 제공: " + 첨부파일출처.join(", ") + "\n";
    if (attach_contents) {
      detail_contents += "16. 첨부파일: " + attach_contents;
    }

    return detail_contents;
  };

  // 비자 기본정보 표 보여주기 (최초 채팅 입장 시)
  const showSimpleVisaInfo = async (basicInfo: any) => {
    initAiAnswer();

    const detailName: string = basicInfo.COUNTRY; //국가명
    const formData: any = makeSimpleViasInfoSource(basicInfo);
    const userQuestion: string =
      "비자 정보를 안내하는 간결한 표가 필요합니다. 표의 행은 '비자 구분', '비자 여부', '체류 기간' 입니다. 표만 간결하게 표시해주세요.";

    // Stream 답변 만들기
    const param: any = {
      command: "create_stream_llm_dream_visa",
      detail_name: detailName,
      form_data: formData,
      question: userQuestion,
    };

    // Stream Request
    const streamResponse = await aiApi.postStream(param);

    const answer: string | undefined = await procAiAnswer(streamResponse);

    if (answer) {
      setNeedFirstMsg(false);
      await saveAiAnswer(answer, chatHistory);
    }
  };

  // 채팅 질문 입력
  const userQuestion = async () => {
    if (!question) {
      return; //  null이면 함수 종료
    }

    // 마지막 2가지 대화
    const lastChatMsg = chatHistory.slice(-2); // 당일 대화 일 수 밖에 없다. 당일 처음 클릭 시 인사하고, 표 제공하고 시작
    let latestAnswer = "";
    let latestQuestion = "";
    for (const chatCont of lastChatMsg) {
      if (chatCont.ANSWER === "" && chatCont.QUESTION !== "") {
        latestQuestion = chatCont.QUESTION;
      } else if (chatCont.ANSWER !== "" && chatCont.QUESTION === "") {
        latestAnswer = chatCont.ANSWER;
      }
    }

    // 사용자 질문 저장 (chatHistory State가 함수내에서는 변하지 않기 때문에 최종 chatList를 리턴받아 사용한다.)
    const chatList: ChatContentProps[] | null = await saveUserQuestion();
    if (chatList === null) return;

    initAiAnswer();

    const agnetName: string = visaBasicInfo.COUNTRY; //국가명
    const formData: any = makeSimpleViasInfoSource(visaBasicInfo);

    const param: any = {
      command: "create_stream_llm_dream_conversation_visa",
      detail_name: agnetName,
      form_data: formData,
      latestQuestion: latestQuestion,
      latestAnswer: latestAnswer,
      question: question,
    };

    // Stream Request
    const streamResponse = await aiApi.postStream(param);

    // LLM 답변
    const answer: string | undefined = await procAiAnswer(streamResponse);

    if (answer) {
      await saveAiAnswer(answer, chatList);
    }
  };

  //========================================================================
  // ETC
  //========================================================================
  // 국가코드로 국가명 가져오기 공통 함수
  const getCountryName = (code: string) => {
    let result: string = "";
    for (const flagInfo of countries) {
      if (flagInfo.code === code) {
        result = flagInfo.label;
      }
    }
    return result;
  };

  // 채팅창내 검색
  const fncSearch = (text: string) => {
    // 채팅 리스트에서 해당 텍스트가 있는 list의 index 찾기
    let resultIds: any = []; // 검색어와 매칭되는 결과 List
    let maxIndex: number = -1; // 검색어와 매칭되는 결과 LIST의 max index
    let maxTagIndex: number = -1; // 검색어와 매칭되는 결과의 HTMLElement id

    // 채팅 이력에서 검색어 (text)와 일치하는 HTMLElement 찾기
    for (let i = 0; i < chatHistory.length; i++) {
      // 검색시 질문과 답변 모두에서 결과를 찾아야함.
      const chatText = chatHistory[i].ANSWER + "//" + chatHistory[i].QUESTION;
      if (chatText.indexOf(text) > -1) {
        resultIds.push(i); // 결과 index 추가
        maxIndex = maxIndex + 1;
        maxTagIndex = i;
      }
    }
    if (maxIndex > -1) {
      // 검색결과가 있으면 검색 네비게이션에 해당 결과 전달하기
      chatSearchNaviRef.current?.setSearchResult(resultIds, maxIndex);
      //채팅 리스트에 검색어와 매칭되는 단어 하이라이트
      chatListRef.current?.setHighlight(text);
      // 가장 최신 매칭 HTMLElement로 이동
      const pos: any = document.getElementById(`chat-list-${maxTagIndex}`)?.offsetTop;
      chatListRef.current?.scrollTo(pos);
    } else {
      toastRef.current?.toast("검색 결과가 없습니다.", "warning", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  // PIN 고정/해제
  const set_visa_chat_set_pin = async () => {
    // 최대 3개만 지정가능
    if (pinList.length > 2 && !isPin) {
      alertNoMorePinRef.current.open();
      return;
    }
    const param: any = {
      command: "set_visa_chat_set_pin",
      user_id: userId + "#" + props.userState.company,
      country: agentName,
      is_pin: !isPin,
    };

    const res = await mainApi.post(param);
    if (res.code === "200") {
      setIsPin(!isPin);
      getHistory();
    } else {
      console.error(res.response.error_msg);
    }
  };

  return (
    <>
      <div id="wrap" className="aiChatRoom">
        {/* 해더 */}
        <ChatHeader
          userState={props.userState}
          isSearchMode={isSearchMode}
          setIsSearchMode={setIsSearchMode}
          agentName={agentName}
          isPin={isPin}
          callback={(payload: any) => {
            if (payload.command === "search_text") {
              // 채팅창내 검색
              fncSearch(payload.text);
            } else if (payload.command === "search_close") {
              // 검색 폼 닫기
              chatListRef.current?.setHighlight("");
              chatListRef.current?.scrollStop(false);
            } else if (payload.command === "pin") {
              // 핀고정/해제
              set_visa_chat_set_pin();
            } else if (payload.command === "open_attachs") {
              // 파일함 열기
              drawerAttachsRef.current.open(props.userState.id, agentName, visaBasicInfo, props.userState.company);
            }
          }}
        />
        {/* 본문 */}
        <div id="container">
          <div id="contents">
            <ChatList
              ref={chatListRef}
              userState={props.userState}
              isSearchMode={isSearchMode}
              chatHistory={chatHistory}
              isAiLoading={isAiLoading}
              agentCode={agentCode}
              agentName={agentName}
              aiAnswer={aiAnswer}
              callback={(payload: any) => {
                if (payload.command === "finish_like_proc") {
                  getChatHistory();
                } else if (payload.command === "dislike") {
                  const info: any = payload.info;
                  drawerDislikeRef.current.open(info.RANGE);
                }
              }}
            />
          </div>
        </div>
        {/* 푸터 */}
        {isSearchMode ? (
          //검색모드일 때
          <ChatSearchNavigation
            ref={chatSearchNaviRef}
            scrollTo={(pos: number) => {
              chatListRef.current?.scrollTo(pos);
            }}
          />
        ) : (
          // 채팅모드
          <ChatInput
            question={question}
            setQuestion={setQuestion}
            isAiLoading={isAiLoading}
            userQuestion={userQuestion}
            stopAiAnswer={stopAiAnswer}
            streamReader={streamReader}
          />
        )}
      </div>
      {/* 개선사항 입력 폼 */}
      <DrawerDislike
        ref={drawerDislikeRef}
        userState={props.userState}
        agentName={agentName}
        callback={(payload: any) => {
          if (payload.command === "finish_dislike_proc") {
            chatListRef.current.scrollStop(true);
            getChatHistory();
          }
        }}
      />
      {/* 파일함 */}
      <DrawerAttachs ref={drawerAttachsRef} callback={(payload: any) => {}} />
      {/* 알림창(핀) */}
      <AlertNoMorePin ref={alertNoMorePinRef} callback={(payload: any) => {}} />
      {/* 토스트 */}
      <Toast ref={toastRef} />
      {/* 로딩바 */}
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default Chat;
