import { useState, useEffect } from "react";

import { Box, Autocomplete, TextField, Avatar, Typography, IconButton, InputAdornment } from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import SearchIcon from "@mui/icons-material/Search";

import { countries, CountryType } from "../../interface/MainInterface";

import { CommonUtils } from "../../utils/common_utils";

interface propsType {
  callback: any;
}

const utils = new CommonUtils();

const ComboAiAgent = (props: propsType) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<CountryType | null>(null);
  const [sortedCountries, setSortedCountries] = useState<CountryType[]>([]);

  const handleInputChange = (inputValue: string) => {
    const startMatches: CountryType[] = [];
    const otherMatches: CountryType[] = [];

    for (const country of sortedCountries) {
      const matches = match(country.label, inputValue);
      
      matches.forEach(match => {
        if (match[0] === 0) {
          startMatches.push(country);
        } else {
          otherMatches.push(country);
        }
      });

      if (startMatches.length === 0) {
        setSortedCountries([...countries]);
      }
      else {
        const sortedMatches = [...startMatches, ...otherMatches];
        setSortedCountries(sortedMatches);
      }
      
    }
  };

  useEffect(() => {
    setSortedCountries([...countries]);
  }, []);

  return (
    <Autocomplete
      id="comboCountries"
      value={value}
      options={sortedCountries}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onChange={(event, newValue) => {
        setValue(newValue);
        props.callback({ command: "selected_ai_agent", country: newValue });
      }}
      autoHighlight
      fullWidth
      forcePopupIcon={false}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="AI 상담사 선택"
          sx={{
            boxSizing: "border-box",
            border: "2px solid #1D81A5",
            borderRadius: "8px",
            "& fieldset": {
              border: "none",
            },
          }}
          inputProps={{
            ...params.inputProps,
            style: {
              height: "15px",
              fontSize: "15px",
              lineHeight: "15px",
              fontWeight: "500",
              padding: "0px",
              border: "none",
              borderRadius: "0",
            },
            ...{ enterKeyHint: "search" },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  sx={{ p: 0 }}
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  <SearchIcon sx={{ color: "#1D81A5" }} />
                </IconButton>
              </InputAdornment>
            ),
            style: {
              padding: "14px",
              paddingLeft: "8px",
              paddingRight: "8px",
            },
          }}
          onChange={(event) => handleInputChange(event.target.value)}
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        // highlight (autosuggest-highlight)
        const matches = match(option.label, inputValue, { insideWords: true });
        const parts = parse(option.label, matches);

        return (
          <li {...props} className="ai-agent-list">
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ mr: 1 }}>
                <Avatar src={utils.makeCtryFlagUrl(option.code)} alt={option.label} sx={{ width: 24, height: 24 }} />
              </Box>
              {parts.map((part, index) => (
                <Typography
                  key={index}
                  sx={{
                    lineHeight: "24px",
                    fontWeight: part.highlight ? "700" : "400",
                    color: part.highlight ? "#1D81A5" : "inherit",
                    textDecoration: part.highlight ? "underline" : "none",
                  }}
                >
                  {part.text}
                </Typography>
              ))}
            </Box>
          </li>
        );
      }}
      PaperComponent={({ children }) => (
        <Box sx={{ mt: "4px", border: "1px solid #1D81A5", borderRadius: "8px", p: 1, backgroundColor: "#ffffff" }}>{children}</Box>
      )}
    />
  );
};

export default ComboAiAgent;
