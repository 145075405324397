export interface userState {
  id: string;
  name: string;
  imageUrl: string;
  company: string;
}
export interface ChatContentProps {
  ID: string;
  COUNTRY: string;
  FLAG: string;
  RANGE: string;
  ANSWER: string;
  QUESTION: string;
  READ_FLAG?: boolean;
  CATEGORY?: string;
  CONTACT?: string;
  EDITOR?: string;
  INQUIRY?: string;
  STATUS?: string;
  EXTRA_LINKS?: any;
}

export interface ChatSetProps {
  ID: string;
  COUNTRY: string;
  CHAT: string;
  RANGE: string;
  FLAG: string;
  IS_PIN?: boolean;
}

export interface CountryType {
  code: string;
  label: string;
  englabel: string;
  suggested?: boolean;
}

export const countries: readonly CountryType[] = [
  { code: "GH", label: "가나", englabel: "Ghana" },
  { code: "GA", label: "가봉", englabel: "Gabon" },
  { code: "GY", label: "가이아나", englabel: "Guyana" },
  { code: "GM", label: "감비아", englabel: "Gambia" },
  { code: "GT", label: "과테말라", englabel: "Guatemala" },
  { code: "GD", label: "그레나다", englabel: "Grenada" },
  { code: "GR", label: "그리스", englabel: "Greece" },
  { code: "GN", label: "기니", englabel: "Guinea" },
  { code: "GW", label: "기니비사우", englabel: "Guinea-Bissau" },
  { code: "NA", label: "나미비아", englabel: "Namibia" },
  { code: "NR", label: "나우루", englabel: "Nauru" },
  { code: "NG", label: "나이지리아", englabel: "Nigeria" },
  { code: "SS", label: "남수단", englabel: "South Sudan" },
  { code: "ZA", label: "남아프리카공화국", englabel: "South Africa" },
  { code: "NL", label: "네덜란드", englabel: "Netherlands" },
  { code: "NP", label: "네팔", englabel: "Nepal" },
  { code: "NO", label: "노르웨이", englabel: "Norway" },
  { code: "NZ", label: "뉴질랜드", englabel: "New Zealand" },
  { code: "NU", label: "니우에", englabel: "Niue" },
  { code: "NE", label: "니제르", englabel: "Niger" },
  { code: "NI", label: "니카라과", englabel: "Nicaragua" },
  { code: "TW", label: "대만", englabel: "Taiwan" },
  { code: "DK", label: "덴마크", englabel: "Denmark" },
  { code: "DO", label: "도미니카공화국", englabel: "Dominican Republic" },
  { code: "DM", label: "도미니카연방", englabel: "Dominica" },
  { code: "DE", label: "독일", englabel: "Germany" },
  { code: "TL", label: "동티모르", englabel: "Timor-Leste" },
  { code: "LA", label: "라오스", englabel: "Lao People's Democratic Republic" },
  { code: "LR", label: "라이베리아", englabel: "Liberia" },
  { code: "LV", label: "라트비아", englabel: "Latvia" },
  { code: "RU", label: "러시아", englabel: "Russian Federation" },
  { code: "LB", label: "레바논", englabel: "Lebanon" },
  { code: "LS", label: "레소토", englabel: "Lesotho" },
  { code: "RO", label: "루마니아", englabel: "Romania" },
  { code: "LU", label: "룩셈부르크", englabel: "Luxembourg" },
  { code: "RW", label: "르완다", englabel: "Rwanda" },
  { code: "LY", label: "리비아", englabel: "Libya" },
  { code: "LT", label: "리투아니아", englabel: "Lithuania" },
  { code: "LI", label: "리히텐슈타인", englabel: "Liechtenstein" },
  { code: "MG", label: "마다가스카르", englabel: "Madagascar" },
  { code: "MH", label: "마셜제도", englabel: "Marshall Islands" },
  {
    code: "FM",
    label: "마이크로네시아",
    englabel: "Micronesia, Federated States of",
  },
  { code: "MO", label: "마카오", englabel: "Macao" },
  { code: "MW", label: "말라위", englabel: "Malawi" },
  { code: "MY", label: "말레이시아", englabel: "Malaysia" },
  { code: "ML", label: "말리", englabel: "Mali" },
  { code: "MX", label: "멕시코", englabel: "Mexico" },
  { code: "MC", label: "모나코", englabel: "Monaco" },
  { code: "MA", label: "모로코", englabel: "Morocco" },
  { code: "MU", label: "모리셔스", englabel: "Mauritius" },
  { code: "MR", label: "모리타니아", englabel: "Mauritania" },
  { code: "MZ", label: "모잠비크", englabel: "Mozambique" },
  { code: "ME", label: "몬테네그로", englabel: "Montenegro" },
  { code: "MD", label: "몰도바", englabel: "Moldova, Republic of" },
  { code: "MV", label: "몰디브", englabel: "Maldives" },
  { code: "MT", label: "몰타", englabel: "Malta" },
  { code: "MN", label: "몽골", englabel: "Mongolia" },
  { code: "US", label: "미국", englabel: "United States", suggested: true },
  { code: "MM", label: "미얀마", englabel: "Myanmar" },
  { code: "VU", label: "바누아투", englabel: "Vanuatu" },
  { code: "BH", label: "바레인", englabel: "Bahrain" },
  { code: "BB", label: "바베이도스", englabel: "Barbados" },
  { code: "BS", label: "바하마", englabel: "Bahamas" },
  { code: "BD", label: "방글라데시", englabel: "Bangladesh" },
  { code: "BJ", label: "베냉", englabel: "Benin" },
  { code: "VE", label: "베네수엘라", englabel: "Venezuela" },
  { code: "VN", label: "베트남", englabel: "Vietnam" },
  { code: "BE", label: "벨기에", englabel: "Belgium" },
  { code: "BY", label: "벨라루스", englabel: "Belarus" },
  { code: "BZ", label: "벨리즈", englabel: "Belize" },
  {
    code: "BA",
    label: "보스니아헤르체고비나",
    englabel: "Bosnia and Herzegovina",
  },
  { code: "BW", label: "보츠와나", englabel: "Botswana" },
  { code: "BO", label: "볼리비아", englabel: "Bolivia" },
  { code: "BI", label: "부룬디", englabel: "Burundi" },
  { code: "BF", label: "부르키나파소", englabel: "Burkina Faso" },
  { code: "BT", label: "부탄", englabel: "Bhutan" },
  {
    code: "MK",
    label: "북마케도니아",
    englabel: "Macedonia, the Former Yugoslav Republic of",
  },
  { code: "BG", label: "불가리아", englabel: "Bulgaria" },
  { code: "BR", label: "브라질", englabel: "Brazil" },
  { code: "BN", label: "브루나이", englabel: "Brunei Darussalam" },
  { code: "WS", label: "사모아", englabel: "Samoa" },
  { code: "SA", label: "사우디아라비아", englabel: "Saudi Arabia" },
  { code: "CY", label: "사이프러스", englabel: "Cyprus" },
  { code: "SM", label: "산마리노", englabel: "San Marino" },
  { code: "ST", label: "상투메프린시페", englabel: "Sao Tome and Principe" },
  { code: "SN", label: "세네갈", englabel: "Senegal" },
  { code: "RS", label: "세르비아", englabel: "Serbia" },
  { code: "SC", label: "세이셸", englabel: "Seychelles" },
  {
    code: "KN",
    label: "세인트 킷츠네비스",
    englabel: "Saint Kitts and Nevis",
  },
  { code: "LC", label: "세인트루시아", englabel: "Saint Lucia" },
  {
    code: "VC",
    label: "세인트빈센트그레나딘",
    englabel: "Saint Vincent and the Grenadines",
  },
  { code: "SO", label: "소말리아", englabel: "Somalia" },
  { code: "SB", label: "솔로몬제도", englabel: "Solomon Islands" },
  { code: "SD", label: "수단", englabel: "Sudan" },
  { code: "SR", label: "수리남", englabel: "Suriname" },
  { code: "LK", label: "스리랑카", englabel: "Sri Lanka" },
  { code: "SE", label: "스웨덴", englabel: "Sweden" },
  { code: "CH", label: "스위스", englabel: "Switzerland" },
  { code: "ES", label: "스페인", englabel: "Spain" },
  { code: "SK", label: "슬로바키아", englabel: "Slovakia" },
  { code: "SI", label: "슬로베니아", englabel: "Slovenia" },
  { code: "SL", label: "시에라리온", englabel: "Sierra Leone" },
  { code: "SG", label: "싱가포르", englabel: "Singapore" },
  { code: "AE", label: "아랍에미리트", englabel: "United Arab Emirates" },
  { code: "AM", label: "아르메니아", englabel: "Armenia" },
  { code: "AR", label: "아르헨티나", englabel: "Argentina" },
  { code: "IS", label: "아이슬란드", englabel: "Iceland" },
  { code: "HT", label: "아이티", englabel: "Haiti" },
  { code: "IE", label: "아일랜드", englabel: "Ireland" },
  { code: "AZ", label: "아제르바이잔", englabel: "Azerbaijan" },
  { code: "AF", label: "아프가니스탄", englabel: "Afghanistan" },
  { code: "AD", label: "안도라", englabel: "Andorra" },
  { code: "AG", label: "안티구아바부다", englabel: "Antigua and Barbuda" },
  { code: "AL", label: "알바니아", englabel: "Albania" },
  { code: "DZ", label: "알제리", englabel: "Algeria" },
  { code: "AO", label: "앙골라", englabel: "Angola" },
  { code: "ER", label: "에리트레아", englabel: "Eritrea" },
  { code: "SZ", label: "에스와티니", englabel: "Swaziland" },
  { code: "EE", label: "에스토니아", englabel: "Estonia" },
  { code: "EC", label: "에콰도르", englabel: "Ecuador" },
  { code: "ET", label: "에티오피아", englabel: "Ethiopia" },
  { code: "SV", label: "엘살바도르", englabel: "El Salvador" },
  { code: "GB", label: "영국", englabel: "United Kingdom" },
  { code: "YE", label: "예멘", englabel: "Yemen" },
  { code: "OM", label: "오만", englabel: "Oman" },
  { code: "AT", label: "오스트리아", englabel: "Austria" },
  { code: "HN", label: "온두라스", englabel: "Honduras" },
  { code: "JO", label: "요르단", englabel: "Jordan" },
  { code: "UG", label: "우간다", englabel: "Uganda" },
  { code: "UY", label: "우루과이", englabel: "Uruguay" },
  { code: "UZ", label: "우즈베키스탄", englabel: "Uzbekistan" },
  { code: "UA", label: "우크라이나", englabel: "Ukraine" },
  { code: "IR", label: "이란", englabel: "Iran, Islamic Republic of" },
  { code: "IL", label: "이스라엘", englabel: "Israel" },
  { code: "EG", label: "이집트", englabel: "Egypt" },
  { code: "IT", label: "이탈리아", englabel: "Italy" },
  { code: "IN", label: "인도", englabel: "India" },
  { code: "ID", label: "인도네시아", englabel: "Indonesia" },
  { code: "JP", label: "일본", englabel: "Japan" },
  { code: "JM", label: "자메이카", englabel: "Jamaica" },
  { code: "ZM", label: "잠비아", englabel: "Zambia" },
  { code: "GQ", label: "적도기니", englabel: "Equatorial Guinea" },
  { code: "GE", label: "조지아", englabel: "Georgia" },
  { code: "CN", label: "중국", englabel: "China" },
  {
    code: "CF",
    label: "중앙아프리카공화국",
    englabel: "Central African Republic",
  },
  { code: "DJ", label: "지부티", englabel: "Djibouti" },
  { code: "ZW", label: "짐바브웨", englabel: "Zimbabwe" },
  { code: "TD", label: "차드", englabel: "Chad" },
  { code: "CZ", label: "체코", englabel: "Czech Republic" },
  { code: "CL", label: "칠레", englabel: "Chile" },
  { code: "CM", label: "카메룬", englabel: "Cameroon" },
  { code: "CV", label: "카보베르데", englabel: "Cape Verde" },
  { code: "KZ", label: "카자흐스탄", englabel: "Kazakhstan" },
  { code: "QA", label: "카타르", englabel: "Qatar" },
  { code: "KH", label: "캄보디아", englabel: "Cambodia" },
  { code: "CA", label: "캐나다", englabel: "Canada" },
  { code: "KE", label: "케냐", englabel: "Kenya" },
  { code: "KM", label: "코모로", englabel: "Comoros" },
  { code: "CR", label: "코스타리카", englabel: "Costa Rica" },
  { code: "CI", label: "코트디부아르", englabel: "Cote d'Ivoire" },
  { code: "CO", label: "콜롬비아", englabel: "Colombia" },
  { code: "CG", label: "콩고공화국", englabel: "Congo, Republic of the" },
  {
    code: "CD",
    label: "콩고민주공화국",
    englabel: "Congo, Democratic Republic of the",
  },
  { code: "KW", label: "쿠웨이트", englabel: "Kuwait" },
  { code: "CK", label: "쿡제도", englabel: "Cook Islands" },
  { code: "HR", label: "크로아티아", englabel: "Croatia" },
  { code: "KG", label: "키르기스스탄", englabel: "Kyrgyzstan" },
  { code: "KI", label: "키리바시", englabel: "Kiribati" },
  { code: "TJ", label: "타지키스탄", englabel: "Tajikistan" },
  { code: "TZ", label: "탄자니아", englabel: "United Republic of Tanzania" },
  { code: "TH", label: "태국", englabel: "Thailand" },
  { code: "TG", label: "토고", englabel: "Togo" },
  { code: "TO", label: "통가", englabel: "Tonga" },
  { code: "TM", label: "투르크메니스탄", englabel: "Turkmenistan" },
  { code: "TV", label: "투발루", englabel: "Tuvalu" },
  { code: "TN", label: "튀니지", englabel: "Tunisia" },
  { code: "TR", label: "튀르키예", englabel: "Turkey" },
  { code: "TT", label: "트리니다드토바고", englabel: "Trinidad and Tobago" },
  { code: "PA", label: "파나마", englabel: "Panama" },
  { code: "PY", label: "파라과이", englabel: "Paraguay" },
  { code: "PK", label: "파키스탄", englabel: "Pakistan" },
  { code: "PG", label: "파푸아뉴기니", englabel: "Papua New Guinea" },
  { code: "PW", label: "팔라우", englabel: "Palau" },
  { code: "PE", label: "페루", englabel: "Peru" },
  { code: "PT", label: "포르투갈", englabel: "Portugal" },
  { code: "PL", label: "폴란드", englabel: "Poland" },
  { code: "FR", label: "프랑스", englabel: "France" },
  { code: "FJ", label: "피지", englabel: "Fiji" },
  { code: "FI", label: "핀란드", englabel: "Finland" },
  { code: "PH", label: "필리핀", englabel: "Philippines" },
  { code: "HU", label: "헝가리", englabel: "Hungary" },
  { code: "AU", label: "호주", englabel: "Australia" },
  { code: "HK", label: "홍콩", englabel: "Hong Kong" },
];

export const QuestionCategory: any = [
  "AI 상담내용",
  " 비자정보 ",
  " 국가정보 ",
  "시스템 에러",
  "  기타   ",
];

export const ChatCategory: any = [
  "질문의 의도를 제대로 파악하지 못합니다.",
  "AI 답변 내용이 부정확합니다.",
  "내용의 업데이트가 필요합니다.",
  "시스템 오류가 발생하였습니다.",
  "답변을 거부합니다.",
  "기타",
];

export interface FaqProps {
  pk?: any;
  sk?: any;
  title?: any;
  answer?: any;
}

export const AcceptDocFiles: any = ["pdf", "xls", "xlsx", "ppt", "pptx", "doc", "docx", "zip"];
export const AcceptImageFiles: any = ["jpg", "jpeg", "png", "gif"];
