import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import MainConteiner from "./mainContainer/MainConteiner";
import "@fontsource/noto-sans-kr";

import { userState } from "./interface/MainInterface";

const theme = createTheme({
  typography: {
    fontFamily: "Spoqa, 맑은 고딕, Malgun Gothic, sans-serif",
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: "inherit",
          fontSize: "inherit",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#1D81A5",
      contrastText: "#fff",
    },
    error: {
      main: "#ff0000",
      contrastText: "#fff",
    },
  },
  zIndex: { drawer: 990, modal: 990 },
});

function App() {
  const [userState, setUserState] = useState<userState>({ id: "", name: "", imageUrl: "", company: "" });

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <MainConteiner userState={userState} setUserState={setUserState} />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
