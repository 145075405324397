import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { userState } from "../../../interface/MainInterface";
import { CommonUtils } from "../../../utils/common_utils";

import { Avatar, Box } from "@mui/material";

interface propsType {
  userState: userState;
  row: any;
  index: number;
  highlightText: string;
}

const utils = new CommonUtils();

const UserMessage = (props: propsType) => {
  return (
    <div className="msg user" key={props.index} id={`chat-list-${props.index}`}>
      <div className="msgTop">
        <span className="idPhoto">
          {/* <img src="https://img.hyundaidreamtour.com/images/aihdt/ico_my.svg" alt="you" /> */}
          {/* <img src={props.userState.imageUrl} alt="you" /> */}
          <Box>
            <Avatar src={props.userState.imageUrl} alt={props.userState.name} sx={{ width: 24, height: 24 }} />
          </Box>
        </span>
        <span className="">{props.userState.name}</span>
      </div>
      <ReactMarkdown
        className="response"
        remarkPlugins={[remarkGfm]}
        components={{
          p: (props) => (
            <p
              {...props}
              style={{
                fontSize: "14px",
                margin: "0px",
              }}
            />
          ),
          strong: (props) => (
            <strong
              {...props}
              style={{
                fontSize: "14px",
                margin: "0px",
                color: "#ffffff",
                backgroundColor: "#000000",
              }}
            />
          ),
          table: (props) => (
            <table
              {...props}
              style={{
                borderCollapse: "collapse",
                width: "100%",
              }}
            />
          ),
          th: (props) => (
            <th
              {...props}
              style={{
                border: "1px solid #000000", // 원하는 선색으로 변경하세요.
                padding: "8px",
                textAlign: "left",
              }}
            />
          ),
          td: (props) => (
            <td
              {...props}
              style={{
                border: "1px solid #000000", // 원하는 선색으로 변경하세요.
                padding: "8px",
                textAlign: "left",
              }}
            />
          ),
        }}
      >
        {props.highlightText !== "" ? props.row.QUESTION.replaceAll(props.highlightText, `**${props.highlightText}**`) : props.row.QUESTION}
      </ReactMarkdown>
      <div className="msgFooter">
        <span className="date">{utils.dateFormat(props.row.RANGE, "YYYYMMDDHHmmssSSS", "YYYY-MM-DD HH:mm")}</span>
      </div>
    </div>
  );
};

export default UserMessage;
