import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Stack, Typography, Button } from "@mui/material";

import { userState } from "../../interface/MainInterface";

interface propsType {
  userState: userState;
  writeFlag: boolean;
}

const QnaHeader = (props: propsType) => {
  let navigate = useNavigate();

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Box sx={{ p: "20px 15px", backgroundColor: "#ffffff" }}>
      <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
        <Stack direction={"row"} spacing={1}>
          <Button
            className="btnPrev"
            sx={{ p: 0, minWidth: "0px", width: "8px", height: "24px", minHeight: "24px" }}
            onClick={() => {
              if (props.writeFlag) {
                const userConfirmed = window.confirm(
                  "수정 사항이 있을 시 모두 초기화 됩니다. 계속하시겠습니까?"
                );
                if (userConfirmed) {
                  navigate("/aiVisa");
                } else {
                  return;
                }
              } else {
                navigate("/aiVisa");
              }
            }}
          />
          <Typography sx={{ fontWeight: "500", fontSize: "16px", lineHeight: "24px", height: "24px" }}>{`문의하기`}</Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default QnaHeader;
