import { useEffect, useRef, useState } from "react";

// MUI
import { Box, Button } from "@mui/material";

// Common module
import { userState } from "../../interface/MainInterface";
import { useWindowSize } from "../../utils/useWindowSize";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";

import QnaHeader from "./QnaHeader";
import QnaList from "./QnaList";
import DrawerQnaInput from "./DrawerQnaInput";
import FooterNavigation from "../../mainContainer/FooterNavigation";

interface propsType {
  userState: userState;
}

const Qna = (props: propsType) => {
  const windowSize = useWindowSize();
  const toastRef: any = useRef();

  const qnaListRef: any = useRef();
  const drawerQnaInputRef: any = useRef();

  // isTransaction
  const [isLoading, setIsLoading] = useState(false);

  const [viewDefaultButton, setViewDefaultButton] = useState(true);
  const [viewQnaUpdateButton, setViewQnaUpdateButton] = useState(false);
  const [viewQnaListButton, setViewQnaListButton] = useState(false);
  const [originQnaInfo, setOriginQnaInfo] = useState<any>({});

  useEffect(() => {
    // 메모리 소거
    return () => {
      setIsLoading(false);
      setViewQnaUpdateButton(false);
      setOriginQnaInfo({});
    };
  }, []);

  return (
    <>
      <Box sx={{ width: "100%", height: `calc(${windowSize.height}px - 0px)`, backgroundColor: "#ffffff" }}>
        {/* 헤더 */}
        <Box sx={{ backgroundColor: "#ffffff", width: "100%", zIndex: "200" }}>
          <QnaHeader userState={props.userState}  writeFlag={false}/>
        </Box>
        <Box
          sx={{
            position: "relative",
            height: `calc(${windowSize.height}px - 64px - 66px - 71px)`,
          }}
          className="noScrollBar"
        >
          {/* 나의 문의내역 Tab */}
          <QnaList
            ref={qnaListRef}
            userState={props.userState}
            callback={(payload: any) => {
              if (payload.command === "view_detail") {
                setOriginQnaInfo(payload.qnaInfo);
                if (payload.qnaInfo.answer_state === "Y") {
                  setViewDefaultButton(false);
                  setViewQnaListButton(true);
                  setViewQnaUpdateButton(false);
                } else {
                  setViewDefaultButton(false);
                  setViewQnaListButton(true);
                  setViewQnaUpdateButton(true);
                }
              }
            }}
          />
        </Box>
        {/* 풋터 */}
        <Box sx={{ zIndex: "200" }}>
          {/* 문의하기 버튼 */}
          <Box sx={{ width: "100%", backgroundColor: "#ffffff" }}>
            <Box sx={{ p: 2 }}>
              <Box sx={{ display: "flex", flexDirection: "row", gap: "6px" }}>
                {viewDefaultButton && (
                  <Button
                    variant="contained"
                    disableElevation
                    fullWidth
                    onClick={() => {
                      drawerQnaInputRef.current.open();
                    }}
                  >
                    {"문의하기"}
                  </Button>
                )}
                {viewQnaListButton && (
                  <Button
                    variant="contained"
                    disableElevation
                    fullWidth
                    // color="secondary"
                    onClick={() => {
                      qnaListRef.current.setSelectedQnaKey("");
                      setOriginQnaInfo({});
                      setViewDefaultButton(true);
                      setViewQnaListButton(false);
                      setViewQnaUpdateButton(false);
                    }}
                    style={{ backgroundColor: "black", color: "white" }}
                  >
                    {"목록"}
                  </Button>
                )}
                {viewQnaUpdateButton && (
                  <Button
                    variant="contained"
                    disableElevation
                    fullWidth
                    onClick={() => {
                      drawerQnaInputRef.current.open();
                    }}
                  >
                    {"수정"}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
          {/* 네비게이션 */}
          <Box sx={{ backgroundColor: "#F5F7F9", width: "100%" }}>
            <FooterNavigation menuName={"qna"} />
          </Box>
        </Box>
      </Box>
      <DrawerQnaInput
        ref={drawerQnaInputRef}
        userState={props.userState}
        originQnaInfo={originQnaInfo}
        callback={(payload: any) => {
          if (payload.command === "finish_qna_input") {
            qnaListRef.current.setSelectedQnaKey("");
            setOriginQnaInfo({});
            setViewDefaultButton(true);
            setViewQnaListButton(false);
            setViewQnaUpdateButton(false);
            qnaListRef.current.get_my_qna_list();
          }
        }}
      />
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default Qna;
