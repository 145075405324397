import axios from "axios";

export class HttpMainApi {
  protected apiUrlPath: string | undefined;

  constructor(url: string | undefined) {
    this.apiUrlPath = url;
  }

  protected async requestPostAxios(requestBody: any): Promise<any> {
    try {
      let header: any = {};
      const response = await axios.post(this.apiUrlPath + "", requestBody, header);
      return response.data;
    } catch (error) {
      console.error(error);
      return { code: "500", response: { error_msg: error } };
    }
  }

  protected async requestPostAxiosWithKey(requestBody: any, tenant_key: string): Promise<any> {
    try {
      let header: any = { headers: { "x-api-key": tenant_key } };
      const response = await axios.post(this.apiUrlPath + "", requestBody, header);
      return response.data;
    } catch (error) {
      console.error(error);
      return { code: "500", response: { error_msg: error } };
    }
  }

  public async auth_request(param: any, tenant_key: string) {
    const response = await this.requestPostAxiosWithKey(param, tenant_key);
    return response;
  }

  public async post(param: any) {
    const response = await this.requestPostAxios(param);
    return response;
  }
}
