import { useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import dayjs from "dayjs";

import { Drawer, Box, Stack, Button, Typography } from "@mui/material";

import { userState, QuestionCategory } from "../../interface/MainInterface";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";

import { HttpMainApi } from "../../interface/main-api";
import QnaHeader from "./QnaHeader";

interface propsType {
  userState: userState;
  originQnaInfo: any; // 원본 QNA 데이터
  callback: any;
}

const mainApi = new HttpMainApi(
  window.location.hostname === process.env.REACT_APP_PROD_URL
    ? `${process.env.REACT_APP_API_URL_PROD}/adminMain`
    : `${process.env.REACT_APP_API_URL_DEV}/adminMain`
);
const DrawerQnaInput = (props: propsType, ref: any) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const titleRef: any = useRef();
  const contentRef: any = useRef();

  const [openDrawer, setOpenDrawer] = useState(false);

  const [title, setTitle] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [content, setContent] = useState("");

  const [btnDisable, setBtnDisable] = useState(false);

  useEffect(() => {
    if (props.originQnaInfo.sk === undefined) titleRef.current?.focus();
    else {
      // 수정하기 모드
      setTitle(props.originQnaInfo.title !== undefined ? props.originQnaInfo.title : "");
      setSelectedCategory(props.originQnaInfo.category !== undefined ? props.originQnaInfo.category : "");
      setContent(props.originQnaInfo.content !== undefined ? props.originQnaInfo.content : "");
    }
    // 메모리 소거
    return () => {
      setIsLoading(false);
      setTitle("");
      setSelectedCategory("");
      setContent("");
      setOpenDrawer(false);
    };
  }, [props.originQnaInfo]);

  const open = useCallback((sk: string) => {
    setOpenDrawer(true);
  }, []);

  const close = () => {
    setTitle("");
    setSelectedCategory("");
    setContent("");
    setOpenDrawer(false);
  };

  // 필수값의 상태에 따라서 부모창 "문의하기" 버튼 disable 컨트롤
  useEffect(() => {
    if (title !== "" && selectedCategory !== "" && content !== "") setBtnDisable(false);
    else setBtnDisable(true);
  }, [title, selectedCategory, content, props]);

  // 문의하기 저장
  const saveQna = async () => {
    if (!window.confirm("작성하신 내용을 문의하시겠습니까?")) return;

    const now = dayjs().format("YYYYMMDDHHmmssSSS");

    let param: any = {
      command: "put_information",
      title: title,
      category: selectedCategory,
      content: content,
    };

    if (props.originQnaInfo.sk === undefined) {
      // 최초 등록
      param.pk = "myask#visa";
      param.sk = `${now}#${props.userState.id}#${props.userState.company}`;
      param.editor_id = props.userState.id;
      param.editor = props.userState.name;
      param.update_dt = now;
      param.answer = "";
      param.answer_state = "N";
      // 문의하기 전용 GSI
      param.myask_visa_pk = `myask#visa#${props.userState.id}#${props.userState.company}`;
      param.myask_visa_sk = now;
    } else {
      // 수정하기
      param.pk = props.originQnaInfo.pk;
      param.sk = props.originQnaInfo.sk;
      param.editor_id = props.originQnaInfo.editor_id;
      param.editor = props.originQnaInfo.editor;
      param.update_dt = now;
      param.answer = props.originQnaInfo.answer;
      param.answer_state = props.originQnaInfo.answer_state;
      // 문의하기 전용 GSI
      param.myask_visa_pk = props.originQnaInfo.myask_visa_pk;
      param.myask_visa_sk = props.originQnaInfo.myask_visa_sk;
    }

    setIsLoading(true);
    const res = await mainApi.post(param);
    if (res.code === "200") {
      toastRef.current?.toast("정상적으로 등록되었습니다.", "success", 3000, { vertical: "top", horizontal: "center" });
      props.callback({ command: "finish_qna_input" }); // 부모 Component에 callback
      close();
    } else {
      console.error("error : ", res.response.error_msg);
      toastRef.current?.toast("Error : " + res.response.error_msg, "error", 3000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    open,
  }));

  return (
    <Drawer anchor={"bottom"} open={openDrawer} onClose={close} PaperProps={{ sx: { width: "100%", height: "100%" } }}>
      {/* 헤더 */}
      <div id="wrap">
        <Box sx={{ backgroundColor: "#ffffff", width: "100%", zIndex: "200" }}>
          <QnaHeader userState={props.userState} writeFlag={true} />
        </Box>
        <div id="container"> 
          <div id="contents">
            <div className="myQform">
              <div className="scroll">
                <Stack className="formTable"
                      direction={"column"}
                >
                  <div className="formTable">
                    <form className="formD" id="formD" style={{ paddingLeft: "12px", marginTop: "0" }}>
                      <label className="formTit required"></label>
                      <span style={{marginLeft: "12px", color: "#b60000"}}>
                        필수 항목입니다.
                      </span>
                    </form>
                  </div>
                  <Stack className="formD" direction={"column"} spacing={1.5} sx={{ pl: 2, pr: 2 }}>
                    <Box>
                      <Typography sx={{ fontWeight: "500", fontSize: "14px", lineHeight: "14px", color: "#666666" }}>
                        <label className="formTit required" htmlFor="writer">{`작성자`}</label>
                      </Typography>
                    </Box>
                    <Box sx={{ border: "1px solid #333333", borderRadius: "8px", backgroundColor: "#f6f6f6", p: "9px" }}>
                      <Typography sx={{ fontWeight: "400", fontSize: "14px", lineHeight: "20px", color: "#000000" }}>
                        {props.userState.name}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack className="formD" direction={"column"} spacing={1.5} sx={{ pl: 2, pr: 2 }}>
                    <Box>
                      <Typography sx={{ fontWeight: "500", fontSize: "14px", lineHeight: "14px", color: "#666666" }}>
                        <label className="formTit required" htmlFor="myQTit">{`제목`}</label>
                      </Typography>
                    </Box>
                    <Box>
                      <input
                        id="myQTit"
                        type="search"
                        name="name"
                        placeholder="제목을 입력해주세요"
                        required
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                        autoComplete="off"
                        ref={titleRef}
                        enterKeyHint="enter"
                      />
                    </Box>
                  </Stack>
                  <Stack className="formD" direction={"column"} spacing={1.5} sx={{ pl: 2, pr: 2 }}>
                    <Box>
                      <Typography sx={{ fontWeight: "500", fontSize: "14px", lineHeight: "14px", color: "#666666" }}>
                        <label className="formTit required">{`문의 유형`}</label>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        overflowX: 'auto',
                        whiteSpace: 'nowrap',
                        scrollbarWidth: 'none', /* Firefox */
                        '&::-webkit-scrollbar': {
                          display: 'none', /* Chrome, Safari, Opera */
                        },
                      }}
                    >
                      {QuestionCategory.map((data: string, index: number) => (
                        <Box
                          key={`cate-${index}`}
                          sx={{
                            display: 'inline-block',
                            margin: '0 4px',
                          }}
                        >
                          <Button
                            key={`cate-${index}`}
                            variant="contained"
                            disableElevation
                            sx={{
                              borderRadius: '25px',
                              color: '#ffffff',
                              fontSize: '14px',
                              fontWeight: '500',
                              minWidth: 'fit-content',
                              backgroundColor: data !== selectedCategory ? '#B4B4B4' : undefined,
                            }}
                            onClick={() => {
                              setSelectedCategory(data);
                            }}
                          >
                            {data}
                          </Button>
                        </Box>
                      ))}
                    </Box>
                  </Stack>
                  <Stack className="formD" direction={"column"} spacing={1.5} sx={{ pl: 2, pr: 2 }}>
                    <Box>
                      <Typography sx={{ fontWeight: "500", fontSize: "14px", lineHeight: "14px", color: "#666666" }}>
                        <label className="formTit required" htmlFor="opinion">{`문의내용`}</label>
                        <span className="textCount">{`${content.length} / 300`}</span>
                      </Typography>
                    </Box>
                    <div>
                      <textarea
                        className="opinion myTextarea" 
                        maxLength={300}
                        id="opinion" 
                        required
                        ref={contentRef}
                        value={content}
                        onChange={(e) => {
                          setContent(e.target.value);
                        }}
                        autoComplete="off"
                        style={{
                          width: "100%",
                          height: "310px",
                        }}
                      />
                    </div>
                  </Stack>
                </Stack>
              </div>
              <Stack direction={"row"} spacing={1.5} sx={{ p: 2 }}>
                <Button
                  className="btnblack"
                  variant="contained"
                  disableElevation
                  fullWidth
                  onClick={() => {
                    const userConfirmed = window.confirm(
                      "수정 사항이 있을 시 모두 초기화 됩니다. 계속하시겠습니까?"
                    );
                    if (userConfirmed) {
                      props.callback({ command: "finish_qna_input" }); // 부모 Component에 callback
                    } else {
                      return;
                    }
                  }}
                  style={{ backgroundColor: "black", color: "white" }}
                >
                  {"목록"}
                </Button>
                <Button
                  className="btnBlue"
                  variant="contained"
                  disableElevation
                  fullWidth
                  onClick={saveQna}
                  disabled={btnDisable}
                >
                  {"등록"}
                </Button>
              </Stack>
            </div>
          </div>
        </div>
      </div>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </Drawer>
  );
};

export default forwardRef(DrawerQnaInput);
