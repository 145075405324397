import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Stack, Typography, Button } from "@mui/material";

import { userState } from "../../interface/MainInterface";

interface propsType {
  userState: userState;
}

const FaqHeader = (props: propsType) => {
  let navigate = useNavigate();

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Box sx={{ p: "20px 15px", backgroundColor: "#ffffff" }}>
      <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
        <Stack direction={"row"} spacing={1}>
          <Button
            className="btnPrev"
            sx={{ p: 0, minWidth: "0px", width: "8px", height: "24px", minHeight: "24px" }}
            onClick={() => {
              navigate("/aiVisa");
            }}
          />
          <Typography sx={{ fontWeight: "500", fontSize: "16px", lineHeight: "24px", height: "24px" }}>{`FAQ`}</Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default FaqHeader;
